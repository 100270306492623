import * as global from './global.js'
import * as spot_tools from './spot_tools.js';
import * as tools from './tools.js'

const apiKey = "rOBZKQgyRxqAUPXgy5mumve2tETIXgqN3wK9PXc0eE";

export const version = "8.4.7"
console.warn('Version ' + version)

//ENV
export const mode = window.location.hostname == "play.spot24.com" ? "prod" : "preprod";//preprod

if (mode == "preprod") {
    document.body.classList.add('preprod')
}


var apiPath;
export var media_path_map;
export var media_path;


apiPath = "https://play-back.spot24.com/api/";
media_path_map = "https://play.spot24.com/points/";
media_path = "https://play-back.spot24.com/storage/";


if (mode == "preprod") {
    console.warn('PREPROD')
    apiPath = "https://spot24-back.greenshift.eu/api/";
    media_path_map = "https://spot24.greenshift.eu/points/";
    media_path = "https://spot24-back.greenshift.eu/storage/";
}



//lang
//console.log(navigator.language,navigator.language.substring(0,2))
let firstLang = navigator.language.substring(0, 2) == "fr" ? "fr" : "en"


export var gameData = {
    lang: firstLang,
    connected: false,
    debug: true,
    mapInfo: false,
    dataLoaded: false,
    unlocked_games: [],
    lastLoad: '',
    notifSave: [],
    notifClosed: [],
    levels: [0],
    userLocation: { latitude: 48.85315955000001, longitude: 2.3483482499999955 },
    localized: false,
    spotNear: [],
    gameNear: [],
};


export var localData = {
    success: [],
    visited_poi: [],
    spots_visited: [],
    events_visited: [],
    debug: true
};
export var allPoints = [];
export var allGames = [];
export var allSuccess = [];
export var allGifts = [];
export var allPartners = [];



const authenticateUser = (user, password) => {
    var token = user + ":" + password;
    var hash = encodeToSave(token);
    return "Basic " + hash;
}


// localstorage

export const getFirstData = (callback, options) => {


    document.body.classList.add('load')
    setTimeout(() => {
        document.body.classList.remove('load')
    }, 400)

    //DIDOMI

    if (window.innerWidth > 768 && document.body.getAttribute('id') != 'desktop') {
        location.href = "desktop.html?page=" + encodeToSave(location.href)
    }

    if (localStorage.getItem("sp24gameData")) {
        gameData = JSON.parse(getAndDecode(localStorage.getItem("sp24gameData"), "sp24gameData"))
        //console.warn("game data :")
        //console.log(gameData)
    }


    const endLoad = (options) => {
        checkConnected(options)

        gameData.dataLoaded = true;
        gameData.lastLoad = Date.now()
        saveGameData()

        document.body.classList.remove('noscroll')
        document.querySelector('.data-preloader').classList.add('off');



        //tracking
        window.gdprAppliesGlobally = false; (function () { (function (e, r) { var t = document.createElement("link"); t.rel = "preconnect"; t.as = "script"; var n = document.createElement("link"); n.rel = "dns-prefetch"; n.as = "script"; var i = document.createElement("link"); i.rel = "preload"; i.as = "script"; var o = document.createElement("script"); o.id = "spcloader"; o.type = "text/javascript"; o["async"] = true; o.charset = "utf-8"; var a = "https://sdk.privacy-center.org/" + e + "/loader.js?target_type=notice&target=" + r; if (window.didomiConfig && window.didomiConfig.user) { var c = window.didomiConfig.user; var d = c.country; var s = c.region; if (d) { a = a + "&country=" + d; if (s) { a = a + "&region=" + s } } } t.href = "https://sdk.privacy-center.org/"; n.href = "https://sdk.privacy-center.org/"; i.href = a; o.src = a; var l = document.getElementsByTagName("script")[0]; l.parentNode.insertBefore(t, l); l.parentNode.insertBefore(n, l); l.parentNode.insertBefore(i, l); l.parentNode.insertBefore(o, l) })("ce617df0-ef23-40a0-af8e-e51c16f0aa8c", "qbWwpz3p") })();


        callback()
    }

    let text = document.querySelector('.data-preloader-text');

    let delay = 0;

    if (gameData.lastLoad) {
        delay = Date.now() - gameData.lastLoad;
    }


    //console.log("DELAY:", delay)

    if (!gameData.dataLoaded || delay > 360000) {


        document.querySelector('.data-preloader').classList.add('show');
        let steps = 7

        text.innerHTML = 'Loading game data 1/' + steps

        getAllPoints(() => {

            text.innerHTML = 'Loading game data 2/' + steps
            getAllGames(() => {

                text.innerHTML = 'Loading game data 3/' + steps
                getAllGifts(() => {

                    text.innerHTML = 'Loading game data 4/' + steps

                    getAllLevels(() => {

                        text.innerHTML = 'Loading game data 5/' + steps
                        //endLoad(options)

                        getAllPartners(() => {

                            text.innerHTML = 'Loading game data 6/' + steps
                            //endLoad(options)

                            getAllSuccess(() => {

                                text.innerHTML = 'Loading game data 7/' + steps
                                endLoad(options)

                                getGlobalNotif()

                                if (gameData.uuid) {
                                    getPersonalNotif(gameData.uuid)
                                }



                            })

                        })


                    })

                })

            })

        })
    } else {


        getStaticData()
        endLoad(options)
        text.innerHTML = ""
    }


}

export const checkConnected = (options) => {

    //console.warn(document.body.getAttribute('id'), 'checkConnected', gameData.connected)

    if (gameData.connected) {
        getLocalData()
        checkVisited()
        updateSuccess()


        if (!localData.firstname || !localData.lastname || !localData.pseudo) {

            if (document.body.getAttribute('id') == 'account') {
                gameData.connected = false;
                saveGameData()
                location.href = "login.html"
            }

            if (document.body.getAttribute('id') != 'complete_account') {
                location.href = "complete_account.html"
            }


        }


    } else {
        //console.warn("No local data")
        document.body.classList.add('not-connected')

        //console.log(options)
        if (options) {
            location.href = options.redirect
        }
    }
}

export const getLocalData = () => {

    localData = JSON.parse(getAndDecode(localStorage.getItem("sp24localData"), "sp24localData"))
    //console.warn("Local data :")
    //console.log(localData)
    gameData.connected = true

}


export const getStaticData = () => {

    //console.warn("LoadStaticData")

    /*
    if (localStorage.getItem("sp24gameData")) {
        gameData = JSON.parse(getAndDecode(localStorage.getItem("sp24gameData"),"sp24gameData"))
        console.log(gameData)
    }
    */

    if (localStorage.getItem("sp24localPoints")) {
        allPoints = JSON.parse(getAndDecode(localStorage.getItem("sp24localPoints")))
        //console.log(allPoints)
    }

    if (localStorage.getItem("sp24localGames")) {
        allGames = JSON.parse(getAndDecode(localStorage.getItem("sp24localGames")))
        //console.log(allGames)
    }

    if (localStorage.getItem("sp24localSuccess")) {
        allSuccess = JSON.parse(getAndDecode(localStorage.getItem("sp24localSuccess")))
        //console.log(allSuccess)
    }

    if (localStorage.getItem("sp24localGifts")) {
        allGifts = JSON.parse(getAndDecode(localStorage.getItem("sp24localGifts"), "sp24localGifts"))
        //console.log(allGifts)
    }

    if (localStorage.getItem("sp24localPartners")) {
        allPartners = JSON.parse(getAndDecode(localStorage.getItem("sp24localPartners"), "sp24localPartners"))
        console.log(allPartners)
    }


}

export const encodeToSave = (string) => {
    return btoa(unescape(encodeURIComponent(string)))
}

export const getAndDecode = (string, name) => {
    //console.log("//DECODE "+name,string)
    if (string) {
        return decodeURIComponent(escape(atob(string)))
    } else {

    }

}


export const saveLocalData = () => {

    //console.warn('save local data')

    //console.log(localData.visited_poi)

    if (localData.visited_poi) {
        let spots_visited = localData.visited_poi.filter(spot => {
            return spot.type != "event"
        })
        let events_visited = localData.visited_poi.filter(spot => {
            return spot.type == "event"
        })

        localData.spots_visited = spots_visited
        localData.events_visited = events_visited

        //console.warn('spots_visited')
        //console.log(spots_visited)
    }

    localStorage.setItem("sp24localData", encodeToSave(JSON.stringify(localData)));
}

export const savePoints = () => {
    localStorage.setItem("sp24localPoints", encodeToSave(JSON.stringify(allPoints)));
}

export const saveGames = () => {
    localStorage.setItem("sp24localGames", encodeToSave(JSON.stringify(allGames)));
}

export const saveSuccess = () => {
    localStorage.setItem("sp24localSuccess", encodeToSave(JSON.stringify(allSuccess)));
}

export const saveGameData = () => {
    localStorage.setItem("sp24gameData", encodeToSave(JSON.stringify(gameData)));
    //console.warn('save game data')
    //console.log(gameData)
}

export const saveGifts = () => {
    localStorage.setItem("sp24localGifts", encodeToSave(JSON.stringify(allGifts)));
}



export const savePartners = () => {
    localStorage.setItem("sp24localPartners", encodeToSave(JSON.stringify(allPartners)));
}

//saveGameData()
//preloader api

const startPreloader = (event) => {
    //console.warn(event)
    //document.querySelector('.preloader').classList.remove('off')
    //document.querySelector('.logo-top-wrapper').classList.add('off')
    //document.querySelector('.notif').classList.add('under')
}

const startPreloaderOk = (event) => {
    //console.warn(event)
    document.querySelector('.preloader').classList.remove('off')
    document.querySelector('.logo-top-wrapper').classList.add('off')
    document.querySelector('.notif').classList.add('under')
}

const stopPreloader = () => {
    // document.querySelector('.preloader').classList.add('off')
    // document.querySelector('.logo-top-wrapper').classList.remove('off')
}

const stopPreloaderOk = () => {
    //console.log("stop preloader")
    document.querySelector('.preloader').classList.add('off')
    document.querySelector('.logo-top-wrapper').classList.remove('off')
}



//get spot by uuid

export const getSpotData = (uuid) => {

    let spot_fund;


    allPoints.forEach(spot => {
        if (spot.uuid == uuid) {
            spot_fund = spot
        }
    })

    return spot_fund;

}

//login user

export async function userLogin(email, password) {

    startPreloaderOk('userLogin');

    let url = new URL(apiPath + 'user/login');

    let body = {
        "email": email,
        "password": password
    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {

        //console.log(response.status)
        stopPreloaderOk();

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                console.log(data);

                localData = data.datas;
                gameData.password = password;
                gameData.uuid = data.datas.uuid;

                if (localData.active) {
                    gameData.connected = true;
                    saveGameData()
                    saveLocalData()
                    getPersonalNotif(gameData.uuid, "/")
                } else {
                    gameData.connected = false;
                    saveGameData()
                    saveLocalData()
                    location.href = "new_account.html#activation"
                }


            });
        }
    }).catch(error => {
        console.log(error);
    });

}


//create user

export async function createUser(email, password) {

    startPreloader('createUser');

    let url = new URL(apiPath + 'user');

    let body = {
        "email": email,
        "password": password
    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas);

                localData = data.datas
                gameData.password = password

                localData.played_game = []
                localData.events_visited = []
                localData.spots_visited = []
                localData.visited_poi = []
                localData.success = []

                //console.log(localData)
                saveLocalData()


                gameData.uuid = data.datas.uuid;
                //console.log(gameData)

                saveGameData()

                document.querySelector('.form-create-step1').classList.add('none')
                document.querySelector('.form-create-step2').classList.remove('none')

            });
        }
    }).catch(error => {
        console.log(error);
    })
        ;

}


//create user

export async function updateUser(infos, step) {

    startPreloaderOk('updateUser');


    let url = new URL(apiPath + 'user/' + gameData.uuid);

    let body = {
        "firstname": infos.firstname,
        "lastname": infos.lastname,
        "pseudo": infos.pseudo,
        "dob": infos.birthdate,
        "phone": infos.phone,
        "country": infos.country,
        "city": infos.city,
        "favorite_sport": infos.sport

    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    //console.log(body, headers)

    fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data);
                saveScoreNotif(0, data.datas.user_score)
                saveMainNotif(data.datas.notifications)

                stopPreloaderOk()

                localData = data.datas.user;


                if (step == 1) {

                    //document.querySelector('.form-complete-step1').classList.add('none')
                    //document.querySelector('.form-complete-step2').classList.remove('none')
                    gameData.connected = true;
                    saveGameData()

                    getUserData("/account.html")
                }

                if (step == 1) {
                    getUserData("/account.html")
                }

                if (step == 2) {

                    getUserData("/account.html")
                }

            });
        }
    }).catch(error => {
        console.log(error);
    })
        ;

}

//active user

export async function activeUser(token) {

    startPreloader('activeUser');

    let url = new URL(apiPath + `user/${gameData.uuid}/activation`);

    let body = {
        "token": token
    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {

        //console.log(response.status)
        stopPreloader();

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data);

                localData = data.datas
                localData.token = data.datas.token;

                localData.played_game = []
                localData.events_visited = []
                localData.spots_visited = []
                localData.visited_poi = []
                localData.success = []

                gameData.connected = true;

                saveLocalData()
                saveGameData()

                location.href = "/complete_account.html"
            });
        }
    }).catch(error => {
        console.log(error);
    })
        ;

}

//check pseudo

export async function checkPseudo(pseudo) {

    if (pseudo) {


        startPreloaderOk('checkPseudo');

        console.log("pseudo", pseudo)

        let url = new URL(apiPath + `user/pseudo/${pseudo}`);

        let headers = {
            "x-app-key": apiKey,
            "x-app-lang": gameData.lang,
            "Content-Type": "application/json",
            "Accept": "application/json",
        };

        fetch(url, {
            method: "GET",
            headers,
        }).then(response => {

            stopPreloaderOk();
            //console.log(response.status)

            //error
            if (response.status != 200) {
                response.json().then(data => {
                    console.log(data);
                    global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
                });
            }

            //success
            if (response.status == 200) {
                response.json().then(data => {

                    console.log(data);
                    //global.showNotif('spot_check', 'Pseudo disponible', 'Vous pouvez le prendre.')

                });
            }
        }).catch(error => {
            console.log(error);

        })
            ;

    }
}

//forgot password

export async function forgotPsw(email) {

    startPreloader('forgotPsw');

    let url = new URL(apiPath + 'user/forgot-password');

    let body = {
        "email": email
    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                document.querySelector('.login-step2').classList.add('none')
                document.querySelector('.login-step3').classList.remove('none')
                console.log(data);

            });
        }
    }).catch(error => {
        console.log(error);
    })
        ;

}


//reset password

export async function resetPsw(reset_uuid, reset_token, password, confirm_password) {

    startPreloader('resetPsw');

    let url = new URL(apiPath + `user/${reset_uuid}/reset-password/${reset_token}`);

    let body = {
        "password": password,
        "confirm_password": confirm_password,
    };

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                console.log(data);
                document.querySelector('.form-create-step1').classList.add('none')
                document.querySelector('.form-create-step2').classList.remove('none')

            });
        }
    }).catch(error => {
        console.log(error);
    })
        ;

}



//player info

export async function getUserData(redirect) {


    startPreloader('getUserData', gameData.uuid);

    let url = new URL(apiPath + 'user/' + gameData.uuid);

    const params = {
        "filter": "visited_poi,played_game,successes,gifts",
    };
    Object.keys(params)
        .forEach(key => url.searchParams.append(key, params[key]));

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };


    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }


        //success
        if (response.status == 200) {
            response.json().then(data => {


                console.log(data)
                localData = data.datas;

                saveLocalData()
                updateSuccess()

                stopPreloader()

                if (redirect) {
                    //getPersonalNotif(gameData.uuid,redirect)
                    location.href = redirect;
                }
            })
        }
    });

}



//get POI

export const checkPoiVisited = (uuid) => {


    if (localData.visited_poi.length > 0) {
        var visited_uuid = [];

        localData.visited_poi.forEach(poi => {
            visited_uuid.push(poi.uuid)
        })



        return visited_uuid.indexOf(uuid) != -1

    }


}

export const checkZipVisited = (zipCode) => {

    if (localData.visited_poi.length > 0) {
        var visited_zip = [];

        localData.visited_poi.forEach(poi => {
            visited_zip.push(poi.zipcode)
        })

        return visited_zip.indexOf(String(zipCode)) != -1

    }
}


export const checkSuccesUnlocked = (uuid) => {

    if (localData.success.length > 0) {
        var unlocked_success = [];

        localData.success.forEach(success => {
            unlocked_success.push(success.uuid)
        })

        return unlocked_success.indexOf(uuid) != -1

    }
}

export const checkVisited = () => {


    if (localData.visited_poi) {
        localData.visited_poi.forEach(poi => {
            spot_tools.findPointById(poi.uuid).visited = true;
            //console.log(spot_tools.findPointById(poi.uuid).name.fr)
        })
    }



}





// GET ALL POINTS

export async function getAllPoints(callback) {


    startPreloader('getAllPoints');

    let url = new URL(apiPath + 'point');

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };


    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        if (response.status == 200) {
            response.json().then(data => {
                allPoints = data.datas

                //console.log(data)

                checkVisited()

                //console.log(allPoints)
                savePoints()
                stopPreloader()

                if (callback) {
                    callback()
                }
            })
        }

    });

}


//unlock Spot info

export async function unlockSpot(poi_uuid) {

    startPreloader('unlockSpot');

    let url = new URL(apiPath + 'activity');


    let body = {
        "name": "VISIT_POI",
        "u_uuid": gameData.uuid,
        "p_uuid": poi_uuid,
    };

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };


    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),

    }).then(response => {


        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                //console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }


        //success
        if (response.status == 200) {
            response.json().then(data => {


                //console.warn('SPOT UNLOCKED')
                //console.log(data.datas)

                //getUserData()

                localData = data.datas.user;

                checkVisited()
                saveLocalData()

                savePoints()

                updateSuccess()
                stopPreloader()


                //console.log("spot unlock", poi_uuid, data.datas.score_user)
                showScoreNotif(poi_uuid, data.datas.score_user)
                showMainNotif(data.datas.notifications)

                spot_tools.showScoreHeader()
                spot_tools.RefreshPoints()
                /*
                                console.log('wtf',poi_uuid,spot_tools.findPointById(poi_uuid),spot_tools.findPointById(poi_uuid).games.length>0)
                                if(spot_tools.findPointById(poi_uuid).games.length>0){
                                    let points_left = spot_tools.findPointById(poi_uuid).games[0].score - spot_tools.getSpotGameRecord(spot_tools.findPointById(poi_uuid).games[0].uuid, poi_uuid)
                
                                    api.gameData.gameNear.push(poi_uuid)
                
                                    global.addMainNotif({
                                        type: 'play',
                                        options: {
                                            spot: spot_tools.findPointById(poi_uuid),
                                            game_type: spot_tools.findPointById(poi_uuid).games[0].type,
                                            game: spot_tools.findPointById(poi_uuid).games[0],
                                            points_left: points_left
                        
                                        },
                        
                                        id: poi_uuid
                                    })
                
                                    
                
                                }
                                */

                /*
                allNotifs.push({
                    type: "score",
                    options: {
                        score: getSpotData(poi_uuid).score,
                    },
                    checked: false
                })

                */


                //checkNotif()

            })
        }
    });

}


//Get all game type 


export async function getAllGames(callback) {

    startPreloader('getAllGames');


    let url = new URL(apiPath + 'game/types');

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };




    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        if (response.status == 200) {
            response.json().then(data => {

                allGames = data.datas;
                //console.log(allGames)
                saveGames();
                stopPreloader();

                if (callback) {
                    callback()
                }

            })
        }

    });

}

//Get all game details 


export async function getAllGamesDetails(callback) {

    startPreloader('getAllGamesDetails');


    let url = new URL(apiPath + 'game');

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };




    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        if (response.status == 200) {
            response.json().then(data => {

                stopPreloader();

                if (callback) {
                    callback(data.datas)
                }

            })
        }

    });

}

//get game detail

export async function getGameDetails(uuid, callback) {

    startPreloader('getGameDetails', uuid);


    let url = new URL(apiPath + 'game/' + uuid);

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };




    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        if (response.status == 200) {
            response.json().then(data => {

                stopPreloader();

                if (callback) {
                    callback(data.datas)
                }

            })
        }

    });

}

///get levels


export async function getAllLevels(callback) {

    startPreloader('getAllLevels');

    let url = new URL(apiPath + 'level');

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };


    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }


        //success
        if (response.status == 200) {
            response.json().then(data => {

                stopPreloader()
                //console.warn('levels')
                //console.log(data.datas);

                gameData.levels = [0]

                data.datas.forEach(level => {
                    gameData.levels.push(level.score)
                })

                //console.log(gameData.levels)

                if (callback) {
                    callback(data)
                }


            })
        }
    });

}


//check if spot has game

export const checkGameInSpot = (poi_uuid, game_uuid) => {


}


//send score

export async function sendScore(poi_uuid, game_uuid, score, training, game_type, callback) {


    if (score && !training) {
        startPreloaderOk('sendScore');


        let url = new URL(apiPath + 'activity');

        let body = {
            "name": "PLAY_GAME",
            "u_uuid": gameData.uuid,
            "p_uuid": poi_uuid,
            "g_uuid": game_uuid,
            "record": score,
            "training": training,
            "type": game_type
        };

        const headers = {
            "x-app-key": apiKey,
            "x-app-lang": gameData.lang,
            "x-app-token": localData.token,
            "Authorization": authenticateUser(localData.email, gameData.password),
            "Content-Type": "application/json",
            "Accept": "application/json",
        };


        console.log(headers, body)

        fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(body),

        }).then(response => {

            //console.log(response.status)

            //error
            if (response.status != 200) {
                response.json().then(data => {
                    console.log(data);

                    stopPreloader();
                    global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
                });
            }


            //success
            if (response.status == 200) {
                response.json().then(data => {


                    //let unlock_game=gameData.unlocked_games.indexOf(game_type)==-1

                    console.log(data)
                    localData = data.datas.user;
                    saveLocalData()

                    updateSuccess()
                    stopPreloader()

                    showMainNotif(data.datas.notifications, game_type)

                    let record = data.datas.notifications.length > 0

                    showScoreNotif(poi_uuid, data.datas.score_user, true, game_type, record, score)

                    if (score * allGames[game_type].coeff >= spot_tools.findPointById(poi_uuid).games[0].score && data.datas.score_user.before < data.datas.score_user.current) {
                        global.addMainNotif({
                            type: "no_points_left",
                            options: {
                                spot: spot_tools.findPointById(poi_uuid),
                                game_type: game_type
                            },
                            id: poi_uuid + "-" + game_uuid
                        })

                    }

                    /*
                                        if(unlock_game){
                                            global.addMainNotif({
                                                type: "unlock_game",
                                                options: {
                                                    game:game_type
                                                },
                                                id: "unlock_"+game_type
                                            })
                                        }
                    */

                    spot_tools.showScoreHeader()
                    getAllGames()
                    //checkUnlockedGame()

                    saveLocalData()
                    stopPreloaderOk()


                    if (callback) {
                        callback()
                    }

                })
            }
        });
    }
}



//get success

export async function getAllSuccess(callback) {

    startPreloader('getAllSuccess');

    let url = new URL(apiPath + 'success');

    const headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };


    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {


        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }


        //success
        if (response.status == 200) {
            response.json().then(data => {

                stopPreloader()
                allSuccess = data.datas;


                //updateSuccess()
                saveSuccess()


                if (callback) {
                    callback(data)
                }

            })
        }
    });

}


export const updateSuccess = () => {


    //checkUnlockedGame()

    //console.warn('Update success')

    if (gameData.connected) {


        allSuccess.forEach(success => {
            var count = 0;

            //console.log(success.type, success.name)

            switch (success.type) {
                case "collection":
                    success.points.forEach(point => {
                        if (checkPoiVisited(point.uuid)) {
                            count++;
                        }

                    })
                    break;

                case "event_amount":

                    count = localData.events_visited.length
                    break;

                case "collection_amount":

                    count = localData.spots_visited.length
                    break;

                case "profile_completed":

                    let dataToCheck = ["firstname", "lastname", "pseudo", "dob", "country", "city", "phone", "favorite_sport"]
                    success.objective = dataToCheck.length;

                    dataToCheck.forEach(info => {
                        //console.log(localData[info])
                        if (localData[info]) {
                            count++;
                        }
                    })
                    break;

                case "collection_around":

                    for (let i = 75001; i < 75021; i++) {
                        if (checkZipVisited(i)) {
                            count++
                        }
                    }

                    success.objective = 20
                    break;

                case "memory_surf":
                case "memory_bmx":
                case "breakdance":
                case "quiz":
                case "skate":
                case "basket":
                    count = checkSuccesUnlocked(success.uuid) ? 1 : 0
                    success.objective = 1
                    break;

                case "games_unlocked":

                    count = gameData.unlocked_games.length;
                    success.objective = 6
                    break;

                default:
                    success.objective = 1
                    break;
            }

            success.count = count;
            //console.log('success progress:' + success.count + "/" + success.objective)

        })



        saveSuccess()
    }
}


//classement

export async function getRank(callback) {

    startPreloader('getRank');
    let url = new URL(apiPath + 'user/rank');

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                if (callback) {
                    callback(data.datas.rank)
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}


// rank user

export async function getRankUser(uuid, callback) {

    startPreloader('getRank User');


    let url = new URL(apiPath + 'user/rank/' + uuid);

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };



    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);

                stopPreloader();
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas.user_position)

                if (callback) {
                    callback(data.datas.user_position)
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}




//CADEAUX


export async function getAllGifts(callback) {

    startPreloader('getAllGifts');

    let url = new URL(apiPath + 'gift');

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                allGifts = data.datas;
                saveGifts();

                //console.log(data)
                if (callback) {
                    callback(data.datas)
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}

//TAS


export async function getPrize(callback) {

    startPreloader('GET PRIZE');

    let url = new URL(apiPath + 'prize');

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas)
                if (callback) {
                    callback(data.datas)
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}




//FAQ


export async function getFAQ(callback) {

    startPreloader('GET FAQ');

    let url = new URL(apiPath + 'faq');

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas)
                if (callback) {
                    callback(data.datas)
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}

export async function sendMsg(subject, message) {

    startPreloader('send message');

    let url = new URL(apiPath + 'message');

    let body = {
        'user_uuid': gameData.uuid,
        "subject": subject,
        "message": message
    };

    console.log(body)

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    }).then(response => {

        //console.log(response.status)

        stopPreloader();

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                console.log(data);

            });
        }
    }).catch(error => {
        console.log(error);
    });

}

// deconnexion

export const unconnect = () => {

    document.querySelector('.preloader').classList.remove('off')
    document.querySelector('.logo-top-wrapper').classList.add('off')

    localStorage.removeItem("sp24localData");
    localStorage.removeItem("sp24localPoints");
    localStorage.removeItem("sp24localGames");
    localStorage.removeItem("sp24localSuccess");
    localStorage.removeItem("sp24gameData");
    localStorage.removeItem("sp24localGifts");


    gameData.connected = false;

    //console.warn('Local date deleted!')
    location.href = "/"
}


export const refresh = () => {

    document.querySelector('.preloader').classList.remove('off')
    document.querySelector('.logo-top-wrapper').classList.add('off')

    localStorage.removeItem("sp24localData");
    localStorage.removeItem("sp24localPoints");
    localStorage.removeItem("sp24localGames");
    localStorage.removeItem("sp24localSuccess");
    localStorage.removeItem("sp24localGifts");

}

// NOTIF


// NOTIFS



export const checkNotif = () => {


    //console.warn('Check Notif', gameData.notifSave)

    if (gameData.notifSave !== null && gameData.notifSave !== undefined) {
        gameData.notifSave.forEach((notif, index) => {
            global.addMainNotif(notif)
        })
    }
}

export const showMainNotif = (notifications, game_type) => {

    //console.warn('SHOW NOTIFICATION')

    notifications.forEach(notif => {
        //console.log(notif)

        if (notif.new_success) {

            global.addMainNotif({
                type: "success",
                options: {
                    success: spot_tools.findSuccessById(notif.new_success)
                },
                id: spot_tools.findSuccessById(notif.new_success).uuid
            })
        }

        if (notif.new_gift) {

            global.addMainNotif({
                type: "gift",
                options: {
                    gift: spot_tools.findGiftById(notif.new_gift)
                },
                id: spot_tools.findGiftById(notif.new_gift).uuid
            })
        }

        if (notif.new_world_record) {

            global.addMainNotif({
                type: "world_record",
                options: {
                    record: notif.new_world_record,
                    game_type: game_type
                },
                id: 'record'
            })
        }

        if (notif.new_perso_record) {

            global.addMainNotif({
                type: "perso_record",
                options: {
                    record: notif.new_perso_record,
                    game_type: game_type
                },
                id: 'record'
            })
        }


        if (notif.description) {


            let date = new Date(notif.updated_at)

            if (Date.now() - date < 60480000) { //7 jours
                global.addMainNotif({
                    type: "global",
                    options: notif,
                    id: notif.id
                })
            }



        }


    })

}

export const saveMainNotif = (notifications) => {

    //console.warn('SHOW NOTIFICATION')

    notifications.forEach(notif => {
        console.log(notif)

        if (notif.new_success) {

            global.saveNotif({
                type: "success",
                options: {
                    success: spot_tools.findSuccessById(notif.new_success)
                },
                id: spot_tools.findSuccessById(notif.new_success).uuid
            })
        }

        if (notif.new_gift) {

            global.saveNotif({
                type: "gift",
                options: {
                    gift: spot_tools.findGiftById(notif.new_gift)
                },
                id: spot_tools.findGiftById(notif.new_gift).uuid
            })
        }

    })

}

export const showScoreNotif = (poi_uuid, score_user, game = false, game_type, record = false, score_game) => {

    //console.warn('GET SCORE')

    if (poi_uuid < 0) {
        global.removeSpotSlide(poi_uuid)
    }

    if (score_user.before < score_user.current) {


        global.addMainNotif({
            type: "score",
            options: {
                score_user: score_user,
                game: game,
                game_type: game_type
            },
            id: "score"
        })

    } else if (game && !record) {
        global.addMainNotif({
            type: "replay",
            options: {
                training: false,
                game_type: game_type,
                score: score_game
            },
            id: 'replay'
        })
    }




}

export const saveScoreNotif = (poi_uuid, score_user) => {

    //console.warn('SAVE SCORE NOTIF')

    if (score_user.current > score_user.before) {
        global.saveNotif({
            type: "score",
            options: {
                score_user: score_user
            },
            id: "score"
        })

    }


}



export const checkUnlockedGame = () => {

    //console.warn('checkUnlockedGame')


    let unlockedGames = [];

    /*
    if (gameData.connected && localData.played_game) {


        localData.played_game.forEach(game => {
            if (unlockedGames.indexOf(game.type) == -1) {
                unlockedGames.push(game.type)
            }
        })

        //console.log(unlockedGames)
    }
    */

    localData.visited_poi.forEach(poi => {


        if (spot_tools.findPointById(poi.uuid).games.length > 0) {
            let type = spot_tools.findPointById(poi.uuid).games[0].type

            if (unlockedGames.indexOf(type) == -1) {
                unlockedGames.push(type)
            }

        }


    })

    //console.log(unlockedGames)

    gameData.unlocked_games = unlockedGames;
    saveGameData()

}

//get global Notif

export async function getGlobalNotif() {

    startPreloader('getGlobalNotif');



    let url = new URL(apiPath + `notification`);

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();


        //error
        if (response.status != 200) {
            response.json().then(data => {
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas);
                showMainNotif(data.datas)


            });
        }
    }).catch(error => {
        console.log(error);

    })


}
//get personal Notif

export async function getPersonalNotif(uuid, redirect) {

    startPreloader('getPersonalNotif');



    let url = new URL(apiPath + `notification/` + uuid);

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "x-app-token": localData.token,
        "Authorization": authenticateUser(localData.email, gameData.password),
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                //console.log(data.datas);

                showMainNotif(data.datas)

                if (redirect) {
                    location.href = redirect
                }

            });
        }
    }).catch(error => {
        console.log(error);

    })


}



//CADEAUX


export async function getAllPartners(callback) {

    startPreloader('getAllPartners');

    let url = new URL(apiPath + 'partner');

    let headers = {
        "x-app-key": apiKey,
        "x-app-lang": gameData.lang,
        "Content-Type": "application/json",
        "Accept": "application/json",
    };

    fetch(url, {
        method: "GET",
        headers,
    }).then(response => {

        stopPreloader();
        //console.log(response.status)

        //error
        if (response.status != 200) {
            response.json().then(data => {
                console.log(data);
                global.showNotif('warning', 'Erreur', data.message, 'Error', data.message)
            });
        }

        //success
        if (response.status == 200) {
            response.json().then(data => {

                allPartners = data.datas;
                savePartners();

                //console.log(data)
                if (callback) {
                    callback()
                }


            });
        }
    }).catch(error => {
        console.log(error);

    })


}