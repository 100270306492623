import * as api from './api.js'
import * as spot_tools from './spot_tools.js'
import * as tools from './tools.js'
import '../css/nav.css';



// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';


var isScrolling = false;

const addScript = async src => new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = src;
    el.addEventListener('load', resolve);
    el.addEventListener('error', reject);
    document.body.append(el);
});




try {
    await addScript('https://livemap.getwemap.com/js/sdk.min.js');
    // do something after it was loaded
} catch (e) {
    console.log(e);
}






export const initAdressMap = (lat, long) => {

    var addressMap = wemap.v1.createLivemap(document.querySelector('.address-map'), {
        emmid: 25996,//26407
        token: 'GK7E6PDBLJ3IW95UY7M0ML0SZ',
        initialRoute: {
            name: 'search',
            coordinates: {
                center: {
                    lat: lat,
                    lng: long
                },
                zoom: 16.5
            },
        }
    });


    addressMap.waitForReady().then(function () {

        //console.warn('FICHE MAP READY')
        addressMap.disablePositioningSystem()
        spot_tools.addPoint(addressMap);

        //console.warn('MAP INIT', lat, long)
        let target = { latitude: lat, longitude: long }
        addressMap.centerTo(target, 16.5)

    })

}


const current = (name) => {
    if (document.querySelector('#' + name)) {
        return "current"
    }
}

export const addNavEvents = () => {
    document.body.innerHTML += `  <nav>
<ul>

    <li class="${current('home')}"><a href="/index.html">
            <img src="../img/icon/white/home.svg" class="nav-picto">
            <div class="nav-text">Accueil</div>
        </a></li>

    <li class="${current('success')}"><a href="/success.html">
            <img src="../img/icon/white/success.svg" class="nav-picto">
            <div class="nav-text" >Succès</div>
        </a></li>

    <li class="${current('map')}"><a href="/map.html">
            <img src="../img/icon/white/spot_check.svg" class="nav-picto">
            <div class="nav-text">Explorer</div>
        </a></li>

    <li class="${current('events')}"><a href="/events.html">
            <img src="../img/icon/white/event.svg" class="nav-picto">
            <div class="nav-text">Events</div>
        </a></li>

    <li class="${current('games')}"><a href="/games.html">
            <img src="../img/icon/white/game.svg" class="nav-picto">
            <div class="nav-text">Jeux</div>
        </a></li>

</ul>
</nav>`;
}


export const addNav = () => {
    document.body.innerHTML += `  <nav>
<ul>

    <li class="${current('home')}"><a href="/index.html">
            <img src="../img/icon/white/home.svg" class="nav-picto">
            <div class="nav-text" data-lang="home">Accueil</div>
        </a></li>

    <li class="${current('success')}"><a href="/success.html">
            <img src="../img/icon/white/success.svg" class="nav-picto">
            <div class="nav-text" data-lang="success">Succès</div>
        </a></li>

    <li class="${current('map')}"><a href="/map.html">
            <img src="../img/icon/white/spot_check.svg" class="nav-picto">
            <div class="nav-text" data-lang="map">Explorer</div>
        </a></li>

    <li class="${current('games')}"><a href="/games.html">
            <img src="../img/icon/white/game.svg" class="nav-picto">
            <div class="nav-text" data-lang="games">Jeux</div>
        </a></li>

    <li class="${current('account')}"><a href="/${api.gameData.connected ? "account" : "login"}.html">
        <img src="../img/icon/white/account.svg" class="nav-picto">
        <div class="nav-text" data-lang="account">Account</div>
    </a></li>   

</ul>
</nav>`;
}

//notif

export const openMainNotif = () => {
    document.querySelector('.main-notif').classList.add('open')
    document.body.classList.add('noscroll')
}

export const closeMainNotif = () => {
    document.querySelector('.main-notif').classList.remove('open')
    document.body.classList.remove('noscroll')
}


//top

export const addTop = () => {

    document.querySelector('header').innerHTML = `
   <a href="${api.gameData.connected ? "account" : "login"}.html" class="header-account">
   <img src="../img/icon/white/account.svg" class="account-icon">

   <svg class="header-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="48" class="header-circle-progress"/>
</svg>

   <div class="header-account-content">
   <div class="header-score"></div>
   <div class="header-level"></div>
   </div>
   </a>
    <div class="header-middle">
    <a href="/" class="logo-top-wrapper">
    <img src="../img/logo-top-full.svg" class="logo-top">

    </a>
    
    <img src="../img/preloader.png" class="preloader off"></div>
    <div class="header-lang top-lang"><img class="header-flag" src="../img/countries/1x1/gb.svg" /></div>
    <div class="header-notif"><img src="../img/icon/white/notif.svg" class="notif-icon"><div class="notif-round"></div></div>

    `;


    document.querySelector('.top-lang').addEventListener('click', () => {
        api.gameData.lang = api.gameData.lang == "fr" ? "en" : "fr"
        api.saveGameData()
        location.reload()
    })



    document.querySelector('.header-flag').setAttribute('src', `../img/countries/1x1/${api.gameData.lang != "fr" ? "fr" : "gb"}.svg`)



    document.querySelector('.header-notif').addEventListener('click', () => {
        if (document.querySelector('.main-notif').classList.contains('open')) {
            closeMainNotif()
        } else {
            if (swiper.slides.length > 0) {
                openMainNotif()
            }

        }

    })

    api.checkNotif()
}

export const addTopAccount = () => {


    document.querySelector('header').innerHTML = `
   <a href="update_account.html" class="header-account">
   <img src="../img/icon/white/pen.svg" class="account-icon">

   <svg class="header-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="48" class="header-circle-progress"/>
</svg>

   <div class="header-account-content">
   <div class="header-score"></div>
   <div class="header-level"></div>
   </div>
   </a>
    <div class="header-middle">
    <a href="/" class="logo-top-wrapper">
    <img src="../img/logo-top-full.svg" class="logo-top">

    </a>
    
    <img src="../img/preloader.png" class="preloader off"></div>
    <div class="header-notif"><img src="../img/icon/white/notif.svg" class="notif-icon"><div class="notif-round"></div></div>
    `;



    document.querySelector('.header-notif').addEventListener('click', () => {
        if (document.querySelector('.main-notif').classList.contains('open')) {
            closeMainNotif()
        } else {
            if (swiper.slides.length > 0) {
                openMainNotif()
            }

        }

    })

    api.checkNotif()
}



export const addTopBack = () => {


    document.querySelector('header').innerHTML = `
   <a href="/" class="header-account">
   <img src="../img/icon/white/back.svg" class="account-icon">

   <svg class="header-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="48" class="header-circle-progress"/>
</svg>

   <div class="header-account-content">
   <div class="header-score"></div>
   <div class="header-level"></div>
   </div>
   </a>
    <div class="header-middle">
    <a href="/" class="logo-top-wrapper">
    <img src="../img/logo-top-full.svg" class="logo-top">

    </a>
    
    <img src="../img/preloader.png" class="preloader off"></div>
    <div class="header-notif"><img src="../img/icon/white/notif.svg" class="notif-icon"><div class="notif-round"></div></div>
    <div class="header-lang"><img class="header-flag" src="../img/countries/1x1/gb.svg" /></div>
    `;



    document.querySelector('.header-notif').addEventListener('click', () => {
        if (document.querySelector('.main-notif').classList.contains('open')) {
            closeMainNotif()
        } else {
            if (swiper.slides.length > 0) {
                openMainNotif()
            }

        }

    })


}

//notif bottom

var swiper;
export const initNotif = () => {

    //console.warn('init swiper')

    swiper = new Swiper('.main-notif  .swiper', {
        speed: 500,
        pagination: {
            el: '.swiper-notif-pagination',
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-notif-next',
            prevEl: '.swiper-notif-prev',
        },
    });

    swiper.on('slideChange', () => {

        if (document.querySelector('.swiper-wrapper .home-score')) {
            if (swiper.slides[swiper.activeIndex].classList.contains('slide-score')) {
                document.querySelector('.swiper-wrapper .home-score').classList.add('anim')
            } else {
                document.querySelector('.swiper-wrapper .home-score').classList.remove('anim')
            }
        }


    })


    swiper.on('update', () => {

        if (document.querySelector('.swiper-wrapper .home-score')) {
            if (swiper.slides[swiper.activeIndex].classList.contains('slide-score')) {
                document.querySelector('.swiper-wrapper .home-score').classList.add('anim')
            } else {
                document.querySelector('.swiper-wrapper .home-score').classList.remove('anim')
            }
        }
    })

}


export const addNotif = () => {

    //console.warn('ADD NOTIF SWIPER')

    document.body.innerHTML += ` <aside class="main-notif">
    <div class="main-notif-middle">
    
        <div class="swiper">
            <div class="swiper-wrapper"></div>
        </div>
    </div>

    <div class="main-notif-bottom">
    <div class="swiper-notif-prev"><img src="../img/icon/white/back.svg" class="back-icon"></div>
    <div class="swiper-notif-pagination"></div>
    <div class="swiper-notif-next"><img src="../img/icon/white/back.svg" class="back-icon"></div>
    </div>
    <div class="notif-overlay"></div>
    </aside>`;



    document.body.innerHTML += `<footer><div class="notif under"><div class="notif-overlay"></div><div class="notif-wrapper"></div></div></footer>`;

    document.querySelector('.main-notif').addEventListener('click', (e) => {

        //console.log(e.target) 

        if (e.target.classList.contains('cta-notif-unlock')) {

            if (api.gameData.connected) {

                api.unlockSpot(e.target.dataset.uuid)

                e.target.classList.remove('classic')
                e.target.classList.add('secondary')

                document.querySelector(`#slide${e.target.dataset.uuid} .spot-card-circle`).classList.add('checked')

            } else {
                location.href = 'login.html'
            }


            spot_tools.pinpoints.forEach(point => {
                if (point.id == e.target.dataset.uuid) {
                    point.visited = true
                    //console.log('point', point)
                }
            })

            //closeMainNotif()

            //swiper.removeSlide(swiper.activeIndex)
            //updateNotif()
        }

        if (e.target.classList.contains('cta-notif-link')) {

            closeMainNotif()
            rememberNotifClosed()

            if (e.target.classList.contains('cta-map') && document.body.getAttribute('id') == "map") {
                setTimeout(() => {

                    swiper.removeSlide(swiper.activeIndex)
                    updateNotif()
                }, 400)
            } else {
                removeNotif(e.target.dataset.nid)
                location.href = e.target.getAttribute('href')
            }

        }

        if (e.target.classList.contains('cta-notif-gift')) {
            spot_tools.openGift(e.target.dataset.nid)

            setTimeout(() => {
                rememberNotifClosed()
                removeNotif(e.target.dataset.nid)
                swiper.removeSlide(swiper.activeIndex)
                updateNotif()
            }, 400)

            closeMainNotif()
        }

        if (e.target.classList.contains('cta-notif-success')) {
            spot_tools.openSuccess(e.target.dataset.nid)

            setTimeout(() => {
                rememberNotifClosed()
                removeNotif(e.target.dataset.nid)
                swiper.removeSlide(swiper.activeIndex)
                updateNotif()
            }, 400)

            closeMainNotif()
        }

        if (e.target.classList.contains('notif-close')) {
            setTimeout(() => {
                rememberNotifClosed()
                removeNotif(e.target.dataset.nid)
                swiper.removeSlide(swiper.activeIndex)
                updateNotif()
            }, 400)

            closeMainNotif()
        }

        if (e.target.classList.contains('cta-notif-game')) {
            setTimeout(() => {
                removeNotif(e.target.dataset.nid)
                swiper.removeAllSlides()
                updateNotif()
            }, 400)

            closeMainNotif()
        }



        if (e.target.classList.contains('cta-start')) {

            setTimeout(() => {
                rememberNotifClosed()
                swiper.removeSlide(swiper.activeIndex)
                updateNotif()
            }, 400)

            closeMainNotif()
        }

        if (e.target.classList.contains('notif-close-spot')) {
            closeMainNotif()
        }

    })

    initNotif()
}


export const resetNotif = () => {
    //swiper.removeAllSlides()
}

export const addMainNotif = (notif) => {
    if (!api.gameData.notifClosed) {
        api.gameData.notifClosed = []
    }


    //console.log(notif)
    //console.log(notif.options)

    if (api.gameData.notifClosed.indexOf(String(notif.id)) == -1 || notif.type == "spot" || notif.type == "play") {
        //console.warn('ADD NOTIF TYPE', notif.type)

        if (notif.type == "spot") {
            ``

            let msg = api.gameData.lang == "fr" ? `Vous pouvez débloquer ce spot pour remporter ${notif.options.spot.score} points.` : `You can unlock this spot to win ${notif.options.spot.score} points.`

            let image = notif.options.spot.image ? api.media_path + notif.options.spot.image : '../img/default.png'

            let partner = notif.options.spot.partner_id ? "partner" : ""

            swiper.appendSlide(`
        <div class="swiper-slide notif-slide notif-slide-spot" data-uuid="${notif.id}" id="slide${notif.id}">

            <div class="main-notif-content" >

            <div class="notif-close-spot" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">
                ${api.gameData.lang == "fr" ? "Vous êtes à proximité de ce spot" : "You are near this spot"}
                </div>

                <div class="spot-notif-card ${notif.options.spot.type} ${partner}">
                    <div class="success-card-info">${notif.options.spot.score + "pts"}</div>
                    <div class="spot-card-circle ">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="white"/>
                    </svg>
                    </div>

                    <img class="spot-notif-img" src="${image}">
                    <div class="spot-notif-gradient"></div>
                    <div class="spot-notif-infos">
                        <div class="spot-notif-title">${notif.options.spot.name[api.gameData.lang]}</div>
                        <div class="spot-notif-type">${notif.options.spot.category[api.gameData.lang]}</div>
                    </div>
                 </div>

                 <div class="main-notif-text">${msg}</div>
                 <div class="cta ${notif.options.spot.type} cta-notif-unlock"  data-uuid="${notif.options.spot.uuid}" data-nid="${notif.id}" >${api.gameData.lang == "fr" ? "Débloquer ce spot" : "Unlock this spot"}</div>

            </div>
        </div>
`)

            swiper.slideTo(swiper.slides.length - 1)
        }

        //play


        if (notif.type == "play") {
            //console.warn('PLAY')

            let msg = api.gameData.lang == "fr" ? `Vous êtes assez proche pour jouer à ce jeu et débloquer les ${notif.options.points_left}pts restants.` : `You are close enough to play this game and unlock the ${notif.options.points_left} remaining points.`

            let image = notif.options.spot.image ? api.media_path + notif.options.spot.image : `../img/games/bkg/${notif.options.game_type}.jpg`

            let gameTitle = `../img/games/title/${notif.options.game_type}.svg`

            let href = `/game_${notif.options.game_type}.html?u=${notif.options.spot.uuid}&g=${notif.options.game.uuid}`


            swiper.appendSlide(`
<div class="swiper-slide notif-slide notif-slide-game" data-uuid="${notif.id}" id="slide${notif.id}">

    <div class="main-notif-content" >

    <div class="notif-close-spot" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
        <div class="main-notif-title">
        ${api.gameData.lang == "fr" ? "Vous pouvez jouer à ce jeu !" : "You can play this game!"}
        </div>

        <div class="spot-notif-card ${notif.options.spot.type}">
            <div class="success-card-info">${notif.options.points_left + "pts"}</div>

            <img class="spot-notif-img game-img" src="${gameTitle}">
            <img class="spot-notif-img" src="${image}">

         </div>

         <div class="main-notif-text">${msg}</div>
         <div href="${href}" class="cta ${notif.options.spot.type} cta-notif-link orange"  data-uuid="${notif.options.spot.uuid}" data-nid="${notif.id}" >${api.gameData.lang == "fr" ? "Jouer au jeu" : "Play now"}</div>

    </div>
</div>
`)


        }


        //score

        if (notif.type == "score") {

            //console.log('SCORE TYPE GAME ', notif.options.game)

            let score_win = notif.options.score_user.current - notif.options.score_user.before


            document.querySelectorAll('.swiper-slide').forEach((slide, index) => {
                //console.log(slide)
                if (slide.classList.contains('slide-score')) {
                    swiper.removeSlide(index)
                }
            })


            let cta = `<div class="cta purple cta-notif-link cta-map" href="map.html" data-nid="${notif.id}">Trouver de nouveaux spots</div>`
            let title = `Félicitations !<br> Vous avez gagné ${score_win}&nbsp;points.`


            if (api.gameData.lang == "en") {
                cta = `<div class="cta purple cta-notif-link cta-map" href="map.html" data-nid="${notif.id}">Find new spots</div>`
                title = `Congratulations!<br> You just won ${score_win}&nbsp;points.`
            }

            if (notif.options.game) {
                cta = `<div class="cta highlight cta-notif-game cta-map" data-nid="${notif.id}"> ${api.gameData.lang == "fr" ? 'Rejouer' : 'Replay'}</div>`
            }

            swiper.appendSlide(`
        <div class="swiper-slide slide-score">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">${title}</div>
                <div class="home-score">

            <div class="home-score-main score-notif">
                <span class="big-score">0</span>
                <span class="pts-score">pts</span>
            </div>

            <div class="lvl-score">
                lvl 1
            </div>

            <div class="top-circle">
                <div class="round-top-circle"></div>
            </div>

            <svg viewBox="0 0 160 160" xmlns="http://www.w3.org/2000/svg" class="home-score-round">
                <circle cx="80" cy="80" r="55" class="circle-score" />

                <circle cx="80" cy="80" r="55" class="circle-hide" />
            </svg>

            <div class="bkg-circle">
            </div>


        </div>

            ${cta}

            </div>
        </div>
`)



            let score_start = notif.options.score_user.before
            let score_end = notif.options.score_user.current


            spot_tools.showScore(score_start, '.main-notif ')

            setTimeout(() => {
                spot_tools.animScore(score_start, score_end, '.main-notif ')
            }, 400)

            if (!notif.options.game) {
                swiper.slideTo(swiper.slides.length - 1)
            }


        }



        if (notif.type == "success") {
            swiper.appendSlide(`
        <div class="swiper-slide">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">
                ${api.gameData.lang == "fr" ? "Vous avez débloqué un nouveau succès." : "You have unlocked a new challenge"}
                </div>

  
                <img class="notif-success-badge" src="${api.media_path + notif.options.success.badge[api.gameData.lang]}">

                <div class="notif-success-title">${notif.options.success.name[api.gameData.lang]}</div>
                <div class="notif-success-text">${notif.options.success.description_short[api.gameData.lang]}</div>


                 <div class="cta pink cta-notif-success" href="/success.html" data-nid="${notif.id}" >
                 ${api.gameData.lang == "fr" ? "Voir le succès" : "See the challenge"}
                 </div>

            </div>
        </div>
`)
            saveNotif(notif)
        }

        //game unlock

        if (notif.type == "unlock_game") {
            swiper.appendSlide(`
        <div class="swiper-slide">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">
                Vous avez débloquez un nouveau jeu.
                </div>

                <div class="spot-notif-card game">

                    <img class="spot-notif-img" src="../img/games/splash/${notif.options.game}.jpg">
 
                 </div>

                 <div class="main-notif-text">Vous pouvez désormais vous entrainer n'importe où pour vous améliorer.</div>
                 <div class="cta orange cta-notif-link"  href="/game_${notif.options.game}.html" data-nid="${notif.id}" >S'entrainer</div>

            </div>
        </div>
`)
            saveNotif(notif)
        }

        // gift unlock

        if (notif.type == "gift") {

            //console.log('gift')

            let image = notif.options.gift.image ? api.media_path + notif.options.gift.image : '../img/default.png'

            let title = `Vous avez débloqué le cadeau ${notif.options.gift.name[api.gameData.lang]}`

            if (api.gameData.lang == "en") {
                title = `You have unlocked the gift ${notif.options.gift.name[api.gameData.lang]}`
            }



            swiper.appendSlide(`
    <div class="swiper-slide notif-slide notif-slide-gift" data-uuid="gift" id="slide${notif.id}">

        <div class="main-notif-content" >

        <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
            <div class="main-notif-title">${title}</div>

            <div class="spot-notif-card gift">
                <img class="spot-notif-img" src="${image}">
             </div>

             <div class="main-notif-description">${notif.options.gift.description[api.gameData.lang]}</div>
             <div class="cta highlight cta-notif-gift" data-nid="${notif.id}" >${api.gameData.lang == "fr" ? "Voir le cadeau" : "See the gift"}</div>


        </div>
    </div>`
            )

            saveNotif(notif)
        }

        //record

        if (notif.type == "world_record") {

            let letters = api.localData.firstname.substring(0, 1) + api.localData.lastname.substring(0, 1);
            let flag = `../img/countries/1x1/${api.localData.country}.svg`

            let record = notif.options.game_type == "basket" ? tools.formatTime(notif.options.record) : notif.options.record

            let word = "score"
            let word_en = "score"

            if (notif.options.game_type == "basket") {
                word = "temps"
                word_en = "time"
            }


            let msg = `<b>Fécilitation ! </b>! Votre ${word} de <b>${record}</b> est la meilleure performance mondiale de tous les temps sur ce jeu.`

            if (api.gameData.lang == "en") {
                msg = `<b>Congratulations!</b>! Your ${word_en} of <b>${record}</b> is the best performance ever recorded in this game.`
            }

            swiper.appendSlide(`
        <div class="swiper-slide">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">
                ${api.gameData.lang == "fr" ? 'Vous venez de battre le record du monde !!!' : 'You just broke the world record!!!'}
                </div>

                <div class="main-notif-record">


            <div class="rank-main first">
            <div class="rank-round ">
                <div class="rank-picture">
                    <div class="rank-letters">${letters}</div>
                    <img class="rank-flag" src="${flag}">
                </div>
            </div>

        </div>

                </div>

                 <div class="main-notif-text">${msg}</div>
                 <div class="cta highlight cta-notif-game"  data-nid="${notif.id}" > ${api.gameData.lang == "fr" ? 'Rejouer' : 'Replay'}</div>

            </div>
        </div>
`)

        }

        //record

        if (notif.type == "perso_record") {

            let letters = api.localData.firstname.substring(0, 1) + api.localData.lastname.substring(0, 1);
            let flag = `../img/countries/1x1/${api.localData.country}.svg`

            let record = notif.options.game_type == "basket" ? tools.formatTime(notif.options.record) : notif.options.record
            let word = "score"
            let word_en = "score"

            if (notif.options.game_type == "basket") {
                word = "temps"
                word_en = "time"
            }


            let msg = `<b>Fécilitation ! </b>! Votre ${word} de <b>${record}</b> est votre meilleure performance réalisée sur ce jeu.`

            if (api.gameData.lang == "en") {
                msg = `<b>Congratulations!</b>! Your ${word_en} of <b>${record}</b> is your best performance in this game.`
            }

            swiper.appendSlide(`
        <div class="swiper-slide">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
                <div class="main-notif-title">

                ${api.gameData.lang == "fr" ? 'Vous venez de battre votre record personnel!' : 'You just broke your own record!'}
                </div>

                <div class="main-notif-record">


            <div class="rank-main">
            <div class="rank-round ">
                <div class="rank-picture">
                    <div class="rank-letters">${letters}</div>
                    <img class="rank-flag" src="${flag}">
                </div>
            </div>

        </div>

                </div>

                 <div class="main-notif-text">${msg}</div>
                 <div class="cta highlight cta-notif-game"  data-nid="${notif.id}" > ${api.gameData.lang == "fr" ? 'Rejouer' : 'Replay'}</div>

            </div>
        </div>
`)

        }

        //replay

        if (notif.type == "replay") {

            let score = notif.options.score;
            let points = Math.round(notif.options.score * api.allGames[notif.options.game_type].coeff);
            let word = "score"
            let word_en = "score"

            if (notif.options.game_type == "basket") {
                score = tools.formatTime(score)
                word = "temps"
                word_en = "time"
            }



            let image = `../img/games/title/${notif.options.game_type}.svg`
            let msg = `<b>Votre ${word} est de ${notif.options.score}</b><br>Il ne vous permet pas de cumuler de nouveaux points sur ce jeu.`

            if (notif.options.training) {
                msg = `<b>Votre ${word} est de ${score}</b><br>Vous auriez pu gagner ${points}pts sur un des spots du jeu.`
            }

            if (api.gameData.lang == "en") {
                msg = `<b>Your ${word_en} is ${notif.options.score}</b><br>It does not allow you to earn any additional points in this game.`

                if (notif.options.training) {
                    msg = `<b>Your ${word_en} is ${score}</b><br>You could have earned ${points} points<br> on one of the dedicated Spot`
                }
            }

            swiper.appendSlide(`
        <div class="swiper-slide slide-replay">

            <div class="main-notif-content" >

            <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>


                <div class="spot-notif-card gift">
                <img class="spot-notif-img" src="${image}">
             </div>

                 <div class="main-notif-text">${msg}</div>
                 <div class="cta highlight cta-notif-game"  data-nid="${notif.id}" > ${api.gameData.lang == "fr" ? 'Rejouer' : 'Replay'}</div>

            </div>
        </div>
`)

        }

        //no more point

        if (notif.type == "no_points_left") {

            let image = notif.options.spot.image ? api.media_path + notif.options.spot.image : '../img/default.png'

            let gameTitle = `../img/games/title/${notif.options.game_type}.svg`

            swiper.appendSlide(`
    <div class="swiper-slide notif-slide" data-uuid="${notif.id}" id="slide${notif.id}">

        <div class="main-notif-content" >

        <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
            <div class="main-notif-title">
            
            ${api.gameData.lang == "fr" ? 'Vous avez débloqué tous les points de ce spot' : 'You have unlocked all the points on this spot for this game!'}
            </div>

            <div class="spot-notif-card gift">
            <img class="spot-notif-img game-img" src="${gameTitle}">
                <img class="spot-notif-img spot-img" src="${image}">
             </div>

             <div class="main-notif-description">
  
             ${api.gameData.lang == "fr" ? 'Cumulez encore plus de points en jouant aux jeux présents sur les autres spots' : 'Earn even more points by playing the games available on the other dedicated Spots!'}
             </div>
             <div class="cta highlight cta-notif-game" data-nid="${notif.id}" > ${api.gameData.lang == "fr" ? 'Rejouer' : 'Replay'}</div>


        </div>
    </div>`
            )


        }

        //global

        if (notif.type == "global") {

            //console.log('global')

            let image = notif.options.image ? api.media_path + notif.options.image : '../img/default.png'
            let cta = `<div class="cta highlight cta-start" data-nid="${notif.id}" >Accéder au jeu</div>`

            let content = notif.options.description[api.gameData.lang] !== null ? notif.options.description[api.gameData.lang] : ""


            if (notif.options.type == "Prize") {
                //console.log('image', notif.options.gift.image)

                image = notif.options.gift.image ? api.media_path + notif.options.gift.image : '../img/default.png'
                cta = `<div class="cta highlight cta-notif-link" data-nid="${notif.id}" href="/account.html#gains">Voir mes gains</div>`
            }

            swiper.appendSlide(`
    <div class="swiper-slide notif-slide" data-uuid="${notif.id}" id="slide${notif.id}">

        <div class="main-notif-content" >

        <div class="notif-close" data-nid="${notif.id}"><img src="../img/icon/white/close.svg"></div>
            <div class="main-notif-title">
             ${notif.options.title[api.gameData.lang]}
            </div>

            <div class="spot-notif-card gift">
                <img class="spot-notif-img" src="${image}">
             </div>

             <div class="main-notif-description">${content}</div>
            ${cta}


        </div>
    </div>`
            )

            swiper.slideTo(0, 500)
        }



        ////
        updateNotif()
        openMainNotif()
        saveNotif(notif)
    }
}




//notif api

export const showNotif = (svg, title, text, title_en, text_en) => {

    //document.querySelector('.notif').classList.add('under')


    document.querySelector('.notif').innerHTML = `  
    <div class="notif-overlay"></div>
    <div class="notif-wrapper">
<div class="notif-img"><img src="../img/icon/white/${svg}.svg" ></div>
<div class="notif-content"><div class="notif-title">${api.gameData.lang == "fr" ? title : title_en}</div><div class="notif-text">${api.gameData.lang == "fr" ? text : text_en}</div></div>
</div>`;

    setTimeout(() => {
        document.querySelector('.notif').classList.remove('under')
    }, 50)

    document.querySelector('.notif').addEventListener('click', () => {
        document.querySelector('.notif').classList.add('under')
    })

}


//

const updateNotif = () => {
    if (swiper.slides.length > 0) {
        document.querySelector('.notif-round').innerHTML = swiper.slides.length;
        document.querySelector('.notif-round').classList.add('show')
        document.querySelector('.header-notif').classList.add('active')
    } else {
        document.querySelector('.notif-round').innerHTML = '';
        document.querySelector('.notif-round').classList.remove('show')
        document.querySelector('.header-notif').classList.remove('active')

        closeMainNotif()
    }
}

export const refreshSpotNotif = () => {

    // console.warn('refreshSpotNotif')

    let slides = []


    //remove old slide

    document.querySelectorAll('.notif-slide-spot').forEach((slide) => {

        if (api.gameData.spotNear.indexOf(slide.dataset.uuid) == -1) {
            removeSpotSlide(slide.dataset.uuid)
            updateNotif()
        }

    })

    document.querySelectorAll('.notif-slide-game').forEach((slide) => {

        if (api.gameData.gameNear.indexOf(slide.dataset.uuid) == -1) {
            removeGameSlide(slide.dataset.uuid)
            updateNotif()
        }

    })
}



export const showSpotNotif = () => {

    let slides = []

    document.querySelectorAll('.notif-slide-spot').forEach((slide, index) => {
        slides.push(slide.dataset.uuid)
    })


    api.gameData.spotNear.forEach(uuid => {

        if (slides.indexOf(uuid) == -1) {

            //console.log('add notif spotNear', uuid)

            addMainNotif({
                type: 'spot',
                options: {
                    spot: spot_tools.findPointById(uuid),
                },

                id: uuid
            })

            openMainNotif()

        }

    })

    //game near


    let slidesGame = []

    document.querySelectorAll('.notif-slide-game').forEach((slide, index) => {
        slidesGame.push(slide.dataset.uuid)
    })



    api.gameData.gameNear.forEach(uuid => {


        let points_left = spot_tools.findPointById(uuid).games[0].score - spot_tools.getSpotGameRecord(spot_tools.findPointById(uuid).games[0].uuid, uuid)

        let lastPlay = true

        if (api.localData.played_game.length > 0 && api.localData.played_game[api.localData.played_game.length - 1].point_uuid == uuid) {
            lastPlay = false
        }

        //console.log(api.localData.played_game[api.localData.played_game.length - 1].point_uuid,uuid)

        if (slidesGame.indexOf(uuid) == -1 && points_left > 0 && lastPlay) {

            //console.log('add notif', spot_tools.findPointById(uuid).name.fr, spot_tools.findPointById(uuid).games[0])

            addMainNotif({
                type: 'play',
                options: {
                    spot: spot_tools.findPointById(uuid),
                    game_type: spot_tools.findPointById(uuid).games[0].type,
                    game: spot_tools.findPointById(uuid).games[0],
                    points_left: points_left

                },

                id: uuid
            })

            //console.log('pass')

            openMainNotif()

        }

    })

}


export const removeSpotSlide = (uuid) => {
    //console.warn('REMOVE SLIDE', uuid)

    swiper.slides.forEach((slide, index) => {

        //console.log(slide)

        if (slide.classList.contains('notif-slide-spot') && slide.dataset.uuid == uuid) {
            swiper.removeSlide(index)
        }
    })

}

export const removeGameSlide = (uuid) => {
    //console.warn('REMOVE GAME SLIDE', uuid)

    swiper.slides.forEach((slide, index) => {

        //console.log(slide)

        if (slide.classList.contains('notif-slide-game') && slide.dataset.uuid == uuid) {
            swiper.removeSlide(index)
        }
    })

}



export const addFiche = () => {

    // fiche spot

    if (api.gameData.lang == "fr") {
        document.body.innerHTML += `<aside class="fiche-spot">
      <div class="header">
        <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
        <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
        <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
        </svg></div></div>
  
      </div>
  
      <div class="fiche-content">
  
          <div class="fiche-top">
              <img class="fiche-main-picture">
              <div class="fiche-top-gradient"></div>
          </div>
          <div class="fiche-info">
          <div class="limited-wrapper none"><img src="../img/icon/white/warning.svg"><div class="limited-content">
          A partir du 26 juillet jusqu’au 11 août, cette zone aura un accès limité en raison des Jeux Olympiques.
          </div></div>
              <div class="fiche-title"></div>
  
              <div class="fiche-under-title">
                  <span class="fiche-type"></span>
                  <span class="small-circle point-distance"></span>
                  <span class="fiche-distance"></span>
                  <span class="small-circle circle-two"></span>
                  <span class="fiche-points"></span>
              </div>
  
              <div class="fiche-description">
  
              </div>
              <div class="see-more">Voir plus</div>
              <div class="see-less none">Voir moins</div>

              <div class="fiche-game none">
                  <div class="fiche-subtitle">Jeu disponible</div>
  
                  <a class="game-line" href="/game_basket.html">
                      <div class="fiche-game-card-main">
                          <div class=fiche-game-card">
                          <div class="spot-card-info">points</div>
                              <img class="fiche-game-card-bkg">
                          </div>
                      </div>
  
                      <div class="game-card-content">
                          <div class="game-card-title">
                          
                          </div>
                          <div class="game-card-text">
                            <div class="text-far">Débloquez ce spot pour jouer au jeu.</div>
                            <div class="text-near">Battez votre record sur ce spot pour débloquer encore plus de points.</div>
                            <div class="text-end none">Félicitations ! <br>Vous avez débloqué tous les points de ce spot.</div>
                          </div>
  
                          <div class="cta orange">Jouer au jeu</div>
                      </div>
                  </a>
  
              </div>
  

              <div class="fiche-spot-success">
                  <div class="fiche-subtitle">Succès à débloquer</div>
  
                  <div class="fiche-spot-success-list">
                  </div>
              </div>
  

              <div class="fiche-info-utile">
                  <div class="fiche-subtitle">Infos utiles</div>
                  <div class="address-map-wrapper">
                  <div class="address-map-icon"><img src="../img/location.svg"></div>
                  

                  <div class="address-map"></div>
                  <div class="address-map-over"></div>
                  </div>
                  <div class="info-line">
                  </div>
                  <ul>
                  <li id="adresse-line">Adresse : <span id="adresse"></span></li>
                  <li id="horaires-line">Horaires : <span id="horaires"></span></li>
                  <li id="tarifs-line">Tarifs : <span id="tarifs"></span></li>
                  <li id="metro-line">Metro : <span id="metro"></span></li>
                  <li id="bus-line">Bus : <span id="bus"></span></li>
                  <li id="rer-line">RER : <span id="rer"></span></li>
                  <li id="tram-line">Tram : <span id="tram"></span></li>
                  <li id="site-line">Site internet : <span id="site"></span></li>
                  <li id="tel-line">Téléphone : <span id="tel"></span></li>
                  </ul>
              </div>

              <div class="fiche-credit"></div>


              <div class="push-bottom-wrapper">
              <a class="fiche-bottom-pjtm" href="https://my.parisjetaime.com/" target="_blank">
              <img src="../img/pjtm_green.svg" class="logo-pjtm-bottom">
               <div class="bottom-push-content">
              Cliquez ici pour découvrir les meilleurs lieux de Paris
               </div>
              </a>
            </div>

          </div>
  
  
      </div>
  
      <div class="fiche-bottom">

      <a href="#" class="cta" id="cta-map"><span>Voir sur la carte</span></a>
      <a href="#" class="cta none" id="cta-navigate"><span>My rendre</span></a>
      <div class="cta none" id="cta-unlock"><span>Débloquer le spot</span></div>


      <a class="cta none" id="cta-play"><span>Jouer au jeu</span></a>
      <div class="fiche-bottom-gradient"></div>
  </div>
  </aside>`;
    }


    if (api.gameData.lang == "en") {
        document.body.innerHTML += `<aside class="fiche-spot">
          <div class="header">
          
            <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
            <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
            <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
            <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
            </svg></div></div>
      
          </div>
      
          <div class="fiche-content">
      
              <div class="fiche-top">
                  <img class="fiche-main-picture">
                  <div class="fiche-top-gradient"></div>
              </div>
              <div class="fiche-info">
                        <div class="limited-wrapper none"><img src="../img/icon/white/warning.svg"><div class="limited-content">
          From 26th July to 11th August, this area will have a limited access due to the Olympics.
          </div></div>

                  <div class="fiche-title"></div>
      
                  <div class="fiche-under-title">
                      <span class="fiche-type"></span>
                      <span class="small-circle point-distance"></span>
                      <span class="fiche-distance"></span>
                      <span class="small-circle circle-two"></span>
                      <span class="fiche-points"></span>
                  </div>
      
                  <div class="fiche-description">
      
                  </div>
                  <div class="see-more">Read more</div>
                  <div class="see-less none">Read less</div>
    
                  <div class="fiche-game none">
                      <div class="fiche-subtitle">Game unvailable</div>
      
                      <a class="game-line" href="/game_basket.html">
                          <div class="fiche-game-card-main">
                              <div class=fiche-game-card">
                              <div class="spot-card-info">points</div>
                                  <img class="fiche-game-card-bkg">
                              </div>
                          </div>
      
                          <div class="game-card-content">
                              <div class="game-card-title">
                              
                              </div>
                              <div class="game-card-text">
                                <div class="text-far">
                                Unlock this spot to play the game</div>
                                <div class="text-near">Beat your own record on this spot to earn even more points.</div>
                                <div class="text-end none">Congratulations!<br>You have earned all the points for this spot.</div>
                              </div>
      
                              <div class="cta orange">Play the game</div>
                          </div>
                      </a>
      
                  </div>
      
    
                  <div class="fiche-spot-success">
                      <div class="fiche-subtitle">Challenge to unlock</div>
      
                      <div class="fiche-spot-success-list">
                      </div>
                  </div>
      
    
                  <div class="fiche-info-utile">
                      <div class="fiche-subtitle">Useful informations</div>
                      <div class="address-map-wrapper"><div class="address-map"></div>
                      <div class="address-map-over"></div>
                      </div>
                      <div class="info-line">
                      </div>
                      <ul>
                      <li id="adresse-line">Address: <span id="adresse"></span></li>
                      <li id="horaires-line">Schedules: <span id="horaires"></span></li>
                      <li id="tarifs-line">Prices: <span id="tarifs"></span></li>
                      <li id="metro-line">Metro: <span id="metro"></span></li>
                      <li id="bus-line">Bus: <span id="bus"></span></li>
                      <li id="rer-line">RER: <span id="rer"></span></li>
                      <li id="tram-line">Tram: <span id="tram"></span></li>
                      <li id="site-line">Website: <span id="site"></span></li>
                      <li id="tel-line">Phone: <span id="tel"></span></li>
                      </ul>
                  </div>
    
                  <div class="fiche-credit"></div>
    
    
                  <div class="push-bottom-wrapper">
                  <a class="fiche-bottom-pjtm" href="https://my.parisjetaime.com/" target="_blank">
                  <img src="../img/pjtm_green.svg" class="logo-pjtm-bottom">
                   <div class="bottom-push-content">
                   Click here to discover the best places in Paris
                   </div>
                  </a>
                </div>
    
              </div>
      
      
          </div>
      
          <div class="fiche-bottom">
    
          <a href="#" class="cta" id="cta-map"><span>See on the map</span></a>
          <a href="#" class="cta none" id="cta-navigate"><span>itinerary</span></a>
          <div class="cta none" id="cta-unlock"><span>unlock the spot</span></div>
    
    
          <a class="cta none" id="cta-play">Play the game</a>
          <div class="fiche-bottom-gradient"></div>
      </div>
      </aside>`;
    }

    // fiche event

    document.body.innerHTML += `<aside class="fiche-event">
  <div class="header">
  
    <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
    <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
    <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
    </svg></div></div>

  </div>

  <div class="fiche-content">

      <div class="fiche-top">
          <img class="fiche-main-picture">
          <div class="fiche-top-gradient"></div>
      </div>
      <div class="fiche-info">
          <div class="fiche-title"></div>

          <div class="fiche-under-title">
              <span class="fiche-type"></span>
              <span class="small-circle"></span>
              <span class="fiche-distance"></span>
              <span class="small-circle circle-two"></span>
              <span class="fiche-points"></span>
          </div>

          <div class="fiche-description">

          </div>
          <div class="see-more">Voir plus</div>
          <div class="see-less none">Voir moins</div>

          <div class="fiche-game none">
              <div class="fiche-subtitle">Jeu disponible</div>

              <a class="game-line" href="/game_basket.html">
                  <div class="fiche-game-card-main">
                      <div class=fiche-game-card">
                          <img class="fiche-game-card-bkg">
                      </div>
                  </div>

                  <div class="game-card-content">
                      <div class="game-card-title">
                      Basketball
                      </div>
                      <div class="game-card-text">
                        <div class="text-far">Rapprochez vous du Spot pour jouer au jeu.</div>
                        <div class="text-near">Battez votre record pour gagne plus de points</div>

                        <div class="game-points">Points restants : 1500pts</div>
                      </div>

                      <div class="cta orange"><span> Jouer </span></div>
                  </div>
              </a>

          </div>


          <div class="fiche-spot-success">
              <div class="fiche-subtitle">Succès à débloquer</div>

              <div class="fiche-spot-success-list">
              </div>
          </div>


          <div class="fiche-info-utile">
              <div class="fiche-subtitle">Infos utiles</div>
              <div class="address-map"></div>
              <div class="info-line">
              </div>
              <ul>
              <li id="adresse-line"><b>Adresse : </b><span id="adresse"></span></li>
              <li id="horaires-line"><b>Horaires : </b><span id="horaires"></span></li>
              <li id="tarifs-line"><b>Tarifs : </b><span id="tarifs"></span></li>
              <li id="metro-line"><b>Metro : </b><span id="metro"></span></li>
              <li id="bus-line"><b>Bus : </b><span id="bus"></span></li>
              <li id="rer-line"><b>RER : </b><span id="rer"></span></li>
              <li id="tram-line"><b>Tram : </b><span id="tram"></span></li>
              <li id="site-line"><b>Site internet : </b><span id="site"></span></li>
              <li id="tel-line"><b>Téléphone : </b><span id="tel"></span></li>
              </ul>
          </div>


      </div>


  </div>

  <div class="fiche-bottom">
  <a href="#" class="cta" id="cta-navigate"><span>My rendre</span></a>
  <div class="fiche-bottom-gradient"></div>

  <div class="cta none" id="cta-unlock"><span>Débloquer le spot</span></div>
  <div class="fiche-bottom-gradient"></div>
</div>
</aside>`;



    //fiche success

    document.body.innerHTML += `<aside class="fiche-success">
  
  <div class="fiche-header">
      
  <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
  <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
  <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
  <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
  </svg></div></div>
  
  </div>
  
  <div class="fiche-content">
  
      <div class="fiche-top">
          <img class="fiche-main-picture">
          <div class="fiche-top-gradient"></div>
      </div>
  
      <div class="fiche-info">
  
          <img class="fiche-success-badge">
  
          <div class="fiche-success-title"></div>
          <div class="fiche-success-description">
  
          </div>
  
          <div class="fiche-success-info">
  
  
              <div class="fiche-success-progress">
                  <div class="fiche-success-bar"></div>
              </div>
  
              <div class="fiche-success-info-bottom"><div class="fiche-success-objectif"></div> <div class="small-circle pink"></div> <div class="fiche-success-points"></div></div> 
          </div>
      </div>
  
      <div class="fiche-success-list">
  
  
      </div>
  
  
  </div>
  
  <div class="fiche-bottom none">
      <a href="map.html" class="cta pink" id="cta-filter"><span>${api.gameData.lang == "fr" ? "Voir sur la carte" : "see ont the map"}</span></a>
      <div class="fiche-bottom-gradient"></div>
  </div>
  
  </aside>`


    // fiche spot

    document.body.innerHTML += `<aside class="fiche-gift">
  <div class="header">
  
    <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
    <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
    <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
    </svg></div></div>
  
  </div>
  
  <div class="fiche-content">
  
      <div class="fiche-top">
          <img class="fiche-main-picture">
          <div class="fiche-top-gradient"></div>
      </div>
      <div class="fiche-info">
          <div class="fiche-gift-type"></div>
          <div class="fiche-gift-title"></div>
          <div class="fiche-gift-text" id="gift-description"></div>
          
  
  
          <div class="fiche-gift-wrapper" id="how-to-win-wrapper">
          <div class="fiche-gift-sep"></div>
            <div class="fiche-gift-wrapper-title">${api.gameData.lang == "fr" ? "Comment gagner ce cadeau ?" : "How to win this gift?"}</div>
           <div class="fiche-gift-text" id="how-to-win"></div>
          </div>
  
          <div class="fiche-gift-wrapper" id="how-to-get-wrapper">
          <div class="fiche-gift-sep"></div>
          <div class="fiche-gift-wrapper-title">${api.gameData.lang == "fr" ? "Comment retirer mon gain ?" : "How do I receive my gift?"}</div>
          <a href="#" target="_blank" class="fiche-code none"></a>
         <div class="fiche-gift-text" id="how-to-get"></div>
  
        </div>
        
        <div class="fiche-gift-wrapper">
        <a href="#" id="fiche-legals">${api.gameData.lang == "fr" ? "Mentions légales" : "Legal mentions"}</a>
      </div>
  
  
      </div>
  
  
  </div>
  
  <div class="fiche-bottom">
  <a href="#" class="cta red" id="cta-get" target="_blank"><span>${api.gameData.lang == "fr" ? "Voir le site" : "See the website"}</span></a>
  <div class="fiche-bottom-gradient"></div>
  
  </aside>`;




    ////cta



    addNotif();

    document.querySelector('.see-more').addEventListener('click', () => {
        document.querySelector('.fiche-description').classList.add('full')
        document.querySelector('.see-more').classList.add('none')
        document.querySelector('.see-less').classList.remove('none')
    })

    document.querySelector('.see-less').addEventListener('click', () => {
        document.querySelector('.fiche-description').classList.remove('full')
        document.querySelector('.see-less').classList.add('none')
        document.querySelector('.see-more').classList.remove('none')
    })


    document.querySelector('.fiche-spot .header-back').addEventListener('click', () => {
        document.querySelector('.fiche-spot').classList.remove('open')
        document.body.classList.remove('fiche-opened')
    })

    document.querySelector('.fiche-event .header-back').addEventListener('click', () => {
        document.querySelector('.fiche-event').classList.remove('open')
        document.body.classList.remove('fiche-opened')
    })

    /*
      document.querySelector('.header-check').addEventListener('click', () => {
        if (!document.querySelector('.header-check').classList.contains('cheked')) {
          api.unlockSpot(selectedSpot.id)
        }
      })
    */





    document.querySelector('.fiche-spot .fiche-content').addEventListener('scroll', () => {
        document.querySelector('.fiche-main-picture').style.transform = `translateY(${document.querySelector('.fiche-spot .fiche-content ').scrollTop / 2}px)`
        isScrolling = true;
    })

    document.querySelector('.fiche-event .fiche-content').addEventListener('scroll', () => {
        document.querySelector('.fiche-main-picture').style.transform = `translateY(${document.querySelector('.fiche-event .fiche-content ').scrollTop / 2}px)`
        isScrolling = true;
    })



    document.querySelector('.fiche-success .header-back').addEventListener('click', () => {
        document.querySelector('.fiche-success').classList.remove('open')
        document.body.classList.remove('fiche-opened')
    })


    document.querySelector('.fiche-success .fiche-content').addEventListener('scroll', () => {
        document.querySelector('.fiche-success .fiche-main-picture').style.transform = `translateY(${document.querySelector('.fiche-success .fiche-content').scrollTop / 2}px)`
        isScrolling = true;
    })


    document.querySelector('.fiche-gift .header-back').addEventListener('click', () => {
        document.querySelector('.fiche-gift').classList.remove('open')
        document.body.classList.remove('fiche-opened')
    })


    document.querySelector('.fiche-gift .fiche-content').addEventListener('scroll', () => {
        document.querySelector('.fiche-gift .fiche-main-picture').style.transform = `translateY(${document.querySelector('.fiche-gift .fiche-content').scrollTop / 2}px)`
        isScrolling = true;
    })

    document.querySelector('.fiche-info-utile').addEventListener('click', (e) => {

        if (e.target.classList.contains('address-map-over')) {
            spot_tools.centerSpot()
        }

    }, false)

}


export const slideToNextNotif = () => {
    //console.log('next')

    swiper.slideNext(500)

    if (swiper.activeIndex < swiper.slides.length - 1) {
        setTimeout(() => {
            slideToNextNotif()
        }, 1500)
    }

}


const rememberNotifClosed = () => {

    if (!api.gameData.notifClosed) {
        api.gameData.notifClosed = []
    }
    api.gameData.notifClosed.push(swiper.slides[swiper.activeIndex].dataset.uuid)

    api.saveGameData()
}

export const saveNotif = (notif) => {

    console.warn('add notif', notif, notif.type)


    if (!api.gameData.notifSave) {
        //console.log('empty ')
        api.gameData.notifSave = []
    }

    let newNotif = true;

    api.gameData.notifSave.forEach(notifSaved => {
        if (notifSaved.id == notif.id) {
            newNotif = false
        }
    })

    //console.log('newNotif', newNotif)

    if (newNotif && notif.type != "spot" && notif.type != "play") {
        api.gameData.notifSave.push(notif)
        api.saveGameData()
    }

}

const removeNotif = (id) => {

    // console.warn('remove notif', id)


    //console.log(api.gameData.notifSave)

    api.gameData.notifSave.forEach((notifSaved, index) => {


        if (notifSaved.type == "score") {
            //console.warn('remove notif score', index)
            api.gameData.notifSave = api.gameData.notifSave.slice(0, index).concat(api.gameData.notifSave.slice(index + 1))
        }

    })

    api.gameData.notifSave.forEach((notifSaved, index) => {

        if (notifSaved.id == id) {
            // console.warn('remove notif index', index)
            api.gameData.notifSave = api.gameData.notifSave.slice(0, index).concat(api.gameData.notifSave.slice(index + 1))
            //console.log(index, api.gameData.notifSave.slice(0, index), api.gameData.notifSave.slice(index + 1))
        }


    })



    api.saveGameData()

}

//slides back

var firstTouch, lastTouch, scrollTouch

document.body.addEventListener('touchstart', (e) => {
    if (document.querySelector('.last-fiche-opened')) {
        firstTouch = e.touches[0].clientX
        scrollTouch = e.touches[0].clientY
        document.querySelector('.last-fiche-opened').classList.add('moveFiche')
    }

    isScrolling = false;

})

document.body.addEventListener('touchend', (e) => {

    if (document.querySelector('.last-fiche-opened')) {

        if (!isScrolling) {

            document.querySelector('.last-fiche-opened').classList.remove('moveFiche')

            let move = (lastTouch - firstTouch) / window.innerWidth * 100
            document.querySelector('.last-fiche-opened').removeAttribute('style')
            document.querySelector('.last-fiche-opened').style.zIndex = spot_tools.zindex;

            if (move > 25) {
                document.querySelector('.last-fiche-opened').classList.remove('open', 'last-fiche-opened')

                if (document.querySelector('.fiche-success').classList.contains('open')) {
                    document.querySelector('.fiche-success').classList.add('last-fiche-opened')
                }

                if (document.querySelector('.fiche-spot').classList.contains('open')) {
                    document.querySelector('.fiche-spot').classList.add('last-fiche-opened')
                }

                if ((!document.querySelector('.fiche-success').classList.contains('open') && !document.querySelector('.fiche-spot').classList.contains('open'))) {
                    document.body.classList.remove('fiche-opened')
                }


            }
        } else {
            document.querySelector('.last-fiche-opened').removeAttribute('style')
            document.querySelector('.last-fiche-opened').style.zIndex = spot_tools.zindex;
        }

    }
})


document.body.addEventListener('touchmove', (e) => {

    if (document.querySelector('.last-fiche-opened')) {
        lastTouch = e.touches[0].clientX
        let move = tools.clamp((lastTouch - firstTouch) / window.innerWidth * 100, 0, 100)

        if (!isScrolling && move > 5) {
            document.querySelector('.last-fiche-opened').style.transform = `translateX(${move}%)`
        }


    }

})

export const add_text_intro_game = (training, urlParams) => {
    if (!training) {
        let points_left = (spot_tools.findPointById(urlParams.get('u')).games[0].score - spot_tools.getSpotGameRecord(urlParams.get('g'), urlParams.get('u')))
        if (points_left > 0) {

            if (api.gameData.lang == "fr") {
                document.querySelector('.game-point-intro').innerHTML = `Il vous reste ${points_left} points<br>à débloquer sur ce spot !`
            }

            if (api.gameData.lang == "en") {
                document.querySelector('.game-point-intro').innerHTML = `You still have ${points_left} points left to unlock on this spot`
            }

        } else {

            if (api.gameData.lang == "fr") {
                document.querySelector('.game-point-intro').innerHTML = `Plus aucun point à gagner sur ce Spot. Un record à battre peut être ?`
            }

            if (api.gameData.lang == "en") {
                document.querySelector('.game-point-intro').innerHTML = `Well done ! No more points to be won on the Spot. A record to beat perhaps?`
            }
        }

    } else {

        if (api.gameData.lang == "fr") {
            document.querySelector('.game-point-intro').innerHTML = `Rendez-vous sur un spot du jeu pour cumuler des points.`
        }
        if (api.gameData.lang == "en") {
            document.querySelector('.game-point-intro').innerHTML = `Well done ! No more points to be won on the Spot. A record to beat perhaps?`
        }
    }
}

export const addScoreGame = (addScore, score) => {
    document.querySelector('.game-score-wrapper .game-score').classList.add('off')

    if (addScore > 0) {
        document.querySelector('.game-score-wrapper .game-score-add').innerHTML = "+ " + addScore
    } else {
        document.querySelector('.game-score-wrapper .game-score-add').innerHTML = addScore
    }

    document.querySelector('.game-score-wrapper .game-score-add').classList.remove('off')
    document.querySelector('.game-score-wrapper .game-score-add').classList.add('anim')

    setTimeout(() => {
        document.querySelector('.game-score-wrapper .game-score').innerHTML = score

        document.querySelector('.game-score-wrapper .game-score').classList.remove('off')
        document.querySelector('.game-score-wrapper .game-score-add').classList.add('off')
        document.querySelector('.game-score-wrapper .game-score-add').classList.remove('anim')
    }, 1000)
}