import * as api from './api.js'

export const translate = () => {

    const elements = document.querySelectorAll('h1,div, span, a, p,input,textarea,label')

    elements.forEach(element => {
        if (element.dataset.lang) {
            //console.log(element.dataset.lang)
            element.innerHTML = all_text[element.dataset.lang][api.gameData.lang]
        }

        if (element.dataset.input) {
            //console.log(element.dataset.input, "/", all_text[element.dataset.input].fr, "/", all_text[element.dataset.input].en)
            element.setAttribute('placeholder', all_text[element.dataset.input][api.gameData.lang])
        }
    });

document.documentElement.setAttribute('lang', api.gameData.lang);

}

// json

const all_text = {
    change_language: { fr: "switch to english", en: "Passer en français" },
    welcome: { fr: "Bienvenue", en: "Welcome" },
    success: { fr: "succès", en: "challenges" },
    games: { fr: "jeux", en: "games" },
    home: { fr: "accueil", en: "home" },
    map: { fr: "explorer", en: "map" },
    see_ranking: { fr: "Voir le classement", en: "See the ranking" },
    unlocked_gift: { fr: "Cadeaux débloqués", en: "Unlocked gifts" },
    success_completed: { fr: "Succès terminés", en: "Challenges completed" },
    faq: { fr: "Foire aux questions", en: "frequently asked questions" },
    need_help: { fr: "besoin d'aide ?  nous contacter", en: "Need help ? Contact us" },
    gdpr: { fr: "rgpd", en: "gpdr" },
    continue: { fr: "continuer", en: "continue" },
    disconnection: { fr: "se déconnecter", en: "sign out" },
    object: { fr: "L'objet de votre message", en: "Your subject" },
    message: { fr: "Votre message", en: "Your message" },
    to_discover: { fr: "A découvrir<br>juste à coté", en: "TO BE DISCOVERED<br>NEARBY" },
    see_map: { fr: "Voir la carte", en: "see the map" },
    success_to_complete: { fr: "Succès<br>à terminer", en: "CHALLENGES<br>TO COMPLETE" },
    see_challenges: { fr: "voir tous les succès", en: "see all challenges" },
    games_to_unlock: { fr: "Jeux<br>à débloquer", en: "Games to<br>unlock" },
    see_all_games: { fr: "Voir tous les jeux", en: "See all games" },
    climbing: { fr: "Escalade", en: "Climbing" },
    to_win_this_week: { fr: "à gagner<br>cette semaine", en: "To win<br>this week" },
    see_your_gift: { fr: "Voir mes gains", en: "see my gifts" },
    next_draw: { fr: "prochain<br>tirage au sort", en: "Next draw" },
    days: { fr: "jours", en: "days" },
    hours: { fr: "heures", en: "hours" },
    minutes: { fr: "minutes", en: "minutes" },
    seconds: { fr: "secondes", en: "seconds" },
    see_all_draw: { fr: "Voir tous les tirages", en: "See all draw" },
    ranking: { fr: "Le classement <br> play spot24", en: "PLAY SPOT24<br>RANKING" },
    spot_24_text: {
    fr: `A l’occasion des Jeux de Paris 2024, Paris je t’aime présente son nouveau lieu hybride dédié à l’accueil des visiteurs (point d’information, billetterie, boutique, SPOT24 Café).<br><br> Point d’orgue de cet espace : « SPOT24, L'Exposition olympique, sport et cultures urbaines » à découvrir jusqu'au 31 décembre à travers un parcours immersif.
    `, 
    en: `On the occasion of the Paris 2024 Games, Paris je t'aime presents its new hybrid venue dedicated to welcoming visitors (information point, ticket office, boutique, SPOT24 Café). <br><br> The highlight of this space is "SPOT24, The Olympic Exhibition, sport and urban cultures", to be discovered until December 31 through an immersive journey.`
    },
    reserve_place: { fr: "JE RÉSERVE UNE PLACE", en: "BOOK A TICKET" },
    follow_us: { fr: "Retrouvez-nous sur", en: "Follow us on" },
    legals: { fr: "Mentions légales", en: "Legal mentions" },
    policy: { fr: "Politique de confidentialité", en: "Privacy policy" },
    cookies: { fr: "Gestion des cookies", en: "Cookie managment" },
    success_title: { fr: "Les succès play spot24", en: "play spot24 challenges" },
    success_text: { fr: "Découvrez nos succès exclusifs en visitant les meilleurs spots de Paris ! ", en: "Discover our exclusive awards by visiting the best spots in Paris! " },
    mini_game: { fr: "Mini jeux", en: "games" },
    games_title: { fr: "Les mini jeux spot24", en: "SPOT24 MINI GAMES" },
    games_text: { fr: `Débloquez les jeux en visitant nos différents Spots associés ! Une fois débloqués, entrainez-vous et tentez de battre le record des Spots ! `, en:`Unlock games by visiting our various associated Spots! Once unlocked, train and try to beat the Spot records!`},
    modify_title: { fr: "mon profil", en: "my profile" },
    modify_cta: { fr: "modifier", en: "modify" },
    firstname: { fr: "Prénom", en: "Firstname" },
    lastname: { fr: "Nom", en: "Lastname" },
    pseudo: { fr: "Pseudo", en: "Nickname" },
    birthdate: { fr: "Date de naissance", en: "Date of birth" },
    phone: { fr: "Téléphone", en: "Phone number" },
    country: { fr: "Pays", en: "Country" },
    city: { fr: "Ville", en: "City" },
    country: { fr: "Pays", en: "Country" },
    favorite_sport: { fr: "Votre sport préféré", en: "Your favorite sport" },
    choose_country: { fr: "Choisissez votre pays", en: "Select your country" },
    new_account_title: { fr: "Création de votre compte", en: "Create new account" },
    password: { fr: "Mot de passe", en: "Password" },
    password_confirm: { fr: "Confirmation de mot de passe", en: "Confirm password" },
    password_notice: { fr: "*8 caractères minimum dont 1 caractère spécial", en: "*8 characters minimum including 1 special character" },
    create_account: { fr: "créer mon compte", en: "Create my account" },
    optin_text1: { fr: "En cochant cette case, vous acceptez le <a href='../pdf/rules_fr.pdf' target='_blank'>règlement</a> Play Spot24", en: "By checking this box, you agree to the <a href=‘../pdf/rules_en.pdf’ target=‘_blank’>Play Spot24 rules</a>." },
    optin_text2: { fr: "En cochant cette case, vous acceptez que vos <a href='../pdf/policy_fr.pdf' target='_blank'>données personnelles</a> soient utilisées selon notre politique de confidentialité", en: "By checking this box, you agree that your <a href=‘../pdf/policy_en.pdf’ target=‘_blank’>personal data</a> will be used according to our privacy policy." },
    enter_code: { fr: "Entrez votre code d'activation", en: "Enter your activation code" },
    account_already: { fr: "Vous avez déjà un compte ?", en: "You already have an account" },
    no_account: { fr: "Vous n'avez pas de compte ? ", en: "You don't have account ? " },
    click_here: { fr: "Cliquez-ici", en: "Click here" },
    login_title: { fr: "Se connecter", en: "Sign in" },
    activate: { fr: "activer mon compte", en: "activate my account" },
    forgotten: { fr: "Mot de passe oublié ? ", en: "Forgot your password ? " },
    index_title: { fr: "Découvrez Paris<br>autrement", en: "Discover Paris <br>from a new angle" },
    complete_title: { fr: "Personnalisons ton profil !", en: "Let's personalize your profile!" },
    contact_us:{fr:`N'hésitez pas à nous contacter si vous avez besoin d'aide sur le jeu. Décrivez bien votre demande et notre équipe vous répondra dans les plus brefs délais.`,en:`Don't hesitate to contact us if you need help with the game. Please describe your request in detail, and our team will respond as quickly as possible.`},
    send:{ fr: "Envoyer le message", en: "Send your message" },
    text_gift:{fr:"Tentez votre chance et essayez de gagner des goodies olympiques, des places pour visiter Paris autrement, sur l'eau ou par les rues de Paris et plein d'autres surprises !",en:"Try your luck for a chance to win some Olympics goodies, visit Paris otherwise on the water or on the road and plenty of other surprises!"},
    start_training:{ fr: "Commencer l'entrainement", en: "Start training" },
    back_home:{ fr: "Retour", en: "Back" },
    back_map:{ fr: "Retour à la carte", en: "Back to the map" },
    replay:{ fr: "Rejouer", en: "Replay" },
    start_game:{ fr: "Commencer le jeu", en: "Start the game" },
    memory:{fr:'Retrouvez cette image<br>avant la fin du chrono',en:'Find this picture<br>before the timer ends'},
    all_games: { fr: "Tous les jeux", en: "All games" },
    all_spots: { fr: "Tous les spots", en: "all spots" },
    account: { fr: "Profil", en: "account" },
    reset:{ fr: "réinitialiser", en: "reset" },
    reset_msg:{ fr: "Un email avec un lien de réinitialisation de mot de passe a été envoyé à l'adresse indiquée.", en: "An email with a password reset link has been sent to the specified address." },
 

}
