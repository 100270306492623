import * as api from './api.js'
import * as tools from './tools.js'
import * as global from './global.js'

var wemap;
export var zindex = 2025;


export const gameName = { skate: { fr: "skateboard", en: "skateboard", title: "Holy skate" }, basket: { fr: "basketball 3x3", en: "basketball 3x3", title: "Globe Trotter" }, breakdance: { fr: "Breakdance", en: "Breakdance", title: "Beat the dust" }, quiz: { fr: "Escalade", en: "Climbing", title: "To the top" }, memory_surf: { fr: "surf", en: "surf", title: "offshore" }, memory_bmx: { fr: "bmx freestyle", en: "bmx freestyle", title: "Flip your mind" } };

export const spotType = { classic: { fr: "spot", en: "spot" }, event: { fr: "événement", en: "event" }, game: { fr: "jeu", en: "game" } }

//distance
export const distance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3;
  const p1 = lat1 * Math.PI / 180;
  const p2 = lat2 * Math.PI / 180;
  const deltaP = p2 - p1;
  const deltaLon = lon2 - lon1;
  const deltaLambda = (deltaLon * Math.PI) / 180;
  const a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
    Math.cos(p1) * Math.cos(p2) *
    Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
  const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
  return d;
}


export const write_distance = (distance) => {

  var dist = Math.round(distance);

  if (dist < 1000) {
    dist += "m"
  } else {
    dist = Math.round(dist / 100) / 10;
    dist += "km"
    dist = dist.replace(/,/g, '.')
  }

  return dist;
}

//init fiche

export const addHeader = (elmnt) => {

  elmnt.innerHTML = `
    <div class="header-back"><img src="../img/icon/white/back.svg" class="back-icon"></div>
    <div class="header-middle"><a href="/"><img src="../img/logo-top-full.svg" class="logo-top"></a></div>
    <div class="header-check"><div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="black"/>
    </svg></div></div>
    `;

}


//find findPointById


export const findPointById = (id) => {
  var targetSpot;

  api.allPoints.forEach(point => {
    if (point.uuid == id) {
      targetSpot = point;
    }
  })

  return targetSpot;
}




export const findSuccessById = (id) => {
  var targetSuccess;

  api.allSuccess.forEach(success => {
    if (success.uuid == id) {
      targetSuccess = success;
    }
  })

  return targetSuccess;
}


export const findGiftById = (id) => {
  var targetGift;

  api.allGifts.forEach(gift => {
    if (gift.uuid == id) {
      targetGift = gift;
    }
  })

  return targetGift;
}



//open fiche

export var selectedSpot;

const removeLine = (data, element) => {
  //console.log(element, data)
  if (data) {
    document.querySelector(element).innerHTML = data;
    document.querySelector(element + "-line").classList.remove('none')
  } else {
    document.querySelector(element).innerHTML = '';
    document.querySelector(element + "-line").classList.add('none')
  }
}

export const openFiche = (point_id) => {


  zindex++;
  document.querySelector('.fiche-spot .fiche-content').scrollTop = 0
  document.querySelector('.fiche-spot').style.zIndex = zindex;


  if (document.querySelector('.last-fiche-opened')) {
    document.querySelector('.last-fiche-opened').classList.remove('last-fiche-opened')
  }

  document.querySelector('.fiche-spot').classList.add('last-fiche-opened')

  if (document.querySelector('.address-map .wemap-livemap')) {
    document.querySelector('.address-map .wemap-livemap').remove()
  }

  document.querySelector('.fiche-main-picture').setAttribute('src', '../img/default.png');

  selectedSpot = findPointById(point_id)
  //console.log(selectedSpot, point_id)

  document.querySelector('.fiche-spot').classList.remove('fiche-type-classic')
  document.querySelector('.fiche-spot').classList.remove('fiche-type-event')
  document.querySelector('.fiche-spot').classList.remove('fiche-type-game')

  document.querySelector('.fiche-spot').classList.add('fiche-type-' + selectedSpot.type)


  updateFicheWithDistance()


  if(selectedSpot.hidden==1){
    document.querySelector('.limited-wrapper').classList.remove('none')
  }else{
    document.querySelector('.limited-wrapper').classList.add('none')
  }


  //document.querySelector('#cta-navigate').setAttribute('href', `navigation.html#/navigate/from/${api.gameData.userLocation.latitude},${api.gameData.userLocation.longitude},0/to/${selectedSpot.latitude},${selectedSpot.longitude},0`);
   document.querySelector('#cta-navigate').setAttribute('href', `navigation.html?spot=${selectedSpot.uuid}`);
 
  document.querySelector('#cta-map').setAttribute('href', `map.html?spot=${selectedSpot.uuid}`);
  document.querySelector('.fiche-title').innerHTML = selectedSpot.name[api.gameData.lang];


  if (selectedSpot.category) {
    document.querySelector('.fiche-type').innerHTML = selectedSpot.category[api.gameData.lang];
  }



  document.querySelector('.fiche-description').innerHTML = selectedSpot.description[api.gameData.lang];
  document.querySelector('.fiche-description').classList.remove('full')
  document.querySelector('.see-less').classList.add('none')

  if (document.querySelector('.fiche-description').offsetHeight < 150) {
    document.querySelector('.see-more').classList.add('none')
  } else (
    document.querySelector('.see-more').classList.remove('none')
  )

  if (selectedSpot.credit) {
    document.querySelector('.fiche-credit').innerHTML = `<div class="credit"><b>${api.gameData.lang == "fr" ? "Crédit photo : " : "Photo credit: "}</b> ${selectedSpot.credit}</div>`;
  } else {
    document.querySelector('.fiche-credit').innerHTML = ""
  }

  if (selectedSpot.hours) {
    removeLine(selectedSpot.hours[api.gameData.lang], '#horaires')
  } else {
    document.querySelector("#horaires-line").classList.add('none')
  }

  if (selectedSpot.more) {
    removeLine(selectedSpot.more[api.gameData.lang], '#tarifs')
  } else {
    document.querySelector("#tarifs-line").classList.add('none')
  }


  removeLine(selectedSpot.metro, '#metro')
  removeLine(selectedSpot.tram, '#tram')
  removeLine(selectedSpot.bus, '#bus')
  removeLine(selectedSpot.rer, '#rer')

  removeLine(`${selectedSpot.address}, ${selectedSpot.zipcode} ${selectedSpot.city}`, '#adresse')

  if (selectedSpot.external_link[api.gameData.lang] && selectedSpot.external_link[api.gameData.lang] !== undefined && selectedSpot.external_link[api.gameData.lang] !== null) {
    removeLine(`<a href="${selectedSpot.external_link[api.gameData.lang]}" target="_blank">${selectedSpot.external_link[api.gameData.lang]}</a>`, '#site')
  } else {
    document.querySelector("#site-line").classList.add('none')
  }

  if (selectedSpot.phone) {
    removeLine(`<a href="tel:${selectedSpot.phone}" target="_blank">${selectedSpot.phone}</a>`, '#tel')
  } else {
    document.querySelector("#tel-line").classList.add('none')
  }



  document.querySelector('.address-map').innerHTML = ""

  setTimeout(() => { global.initAdressMap(selectedSpot.latitude, selectedSpot.longitude), 1000 })


  ////////// GAME

  if (selectedSpot.type == "game" && selectedSpot.games[0]) {

    let points_left = (selectedSpot.games[0].score - getSpotGameRecord(selectedSpot.games[0].uuid, selectedSpot.uuid))

    document.querySelector('.fiche-game-card-main .spot-card-info').innerHTML = points_left + "pts"

    if (points_left <= 0) {
      document.querySelector('.text-end').classList.remove('none')
      document.querySelector('.text-near').classList.add('none')
      document.querySelector('.text-far').classList.add('none')
    } else {
      document.querySelector('.text-end').classList.add('none')
      document.querySelector('.text-near').classList.remove('none')
      document.querySelector('.text-far').classList.remove('none')
    }

    //console.log(selectedSpot.games[0].uuid)

    document.querySelector('.game-card-title').innerHTML = gameName[selectedSpot.games[0].type][api.gameData.lang]
    document.querySelector('.fiche-game-card-bkg').setAttribute('src', '../img/games/splash/' + selectedSpot.games[0].type + '.jpg')

    document.querySelector('.fiche-game').classList.remove('none')
    document.querySelector('.game-line').setAttribute('href', `/game_${selectedSpot.games[0].type}.html?u=${selectedSpot.uuid}&g=${selectedSpot.games[0].uuid}`)
    document.querySelector('#cta-play').setAttribute('href', `/game_${selectedSpot.games[0].type}.html?u=${selectedSpot.uuid}&g=${selectedSpot.games[0].uuid}`)

  } else {
    document.querySelector('.fiche-game').classList.add('none')
  }


  if (selectedSpot.visited) {
    document.querySelector('.fiche-spot .check').classList.add('checked')
    document.querySelector('.fiche-points').innerHTML = "débloqué";
  } else {
    document.querySelector('.fiche-spot .check').classList.remove('checked')
    document.querySelector('.fiche-points').innerHTML = selectedSpot.score + "pts";
  }


  document.querySelector('.fiche-spot').classList.add('open')



  //success

  document.querySelector('.fiche-spot-success-list').innerHTML = "";


  var showAmount = false;
  var nSuccess = 0;

  api.allSuccess.forEach(success => {

    let haveSuccess = false;
    let percent;

    //console.log(success.type)

    if (success.type == "collection") {

      success.points.forEach(point => {

        //console.log(success.name.fr,success.count,success.objective)

        if (point.uuid == selectedSpot.uuid && (success.count < success.objective || !api.gameData.connected)) {
          haveSuccess = true;
        }
      })

    }

    if (success.type == "collection_around") {

      if (!api.checkZipVisited(selectedSpot.zipcode) && parseInt(selectedSpot.zipcode) < 76000 && parseInt(selectedSpot.zipcode) >= 75000) {
        haveSuccess = true;
      }

    }

    if (success.type == "collection_amount" && selectedSpot.type != "event") {

      if (api.localData.spots_visited.length < success.objective && !showAmount) {
        haveSuccess = true;
        showAmount = true;
      }

    }

    if (success.type == "event_amount" && selectedSpot.type == "event") {

      if (api.localData.spots_visited.length < success.objective && !showAmount) {
        haveSuccess = true;
        showAmount = true;
      }

    }



    //console.log(success.type,success.name.fr,haveSuccess)


    if (haveSuccess) {

      if (success.objective) {
        percent = tools.clamp(Math.round(success.count / success.objective * 100), 0, 100)
      } else {
        percent = 0;
      }

      nSuccess++;

      document.querySelector('.fiche-spot-success-list').innerHTML += `
        <div class="success-item" data-id="${success.uuid}">
        <div class="success-badge">
            <img src="${api.media_path + success.badge[api.gameData.lang]}" alt="">
        </div>
        <div class="success-title">
        ${success.name[api.gameData.lang]}
        </div>
        <div class="success-progress">
            <div class="progress-bar" style="width:${percent}%"></div>
        </div>
      </div>
`
    }

    if (nSuccess > 0) {
      document.querySelector('.fiche-spot-success').classList.remove('none')
    } else (
      document.querySelector('.fiche-spot-success').classList.add('none')
    )

    let image = selectedSpot.image ? api.media_path + selectedSpot.image : '../img/default.png'
    document.querySelector('.fiche-main-picture').setAttribute('src', image);




  })


  document.querySelectorAll('.success-item').forEach(success => {
    success.addEventListener('click', (e) => {
      openSuccess(e.currentTarget.dataset.id)
      document.querySelector('.fiche-spot').classList.remove('open', 'last-fiche-opened')
    })
  })



  document.querySelector('#cta-unlock').addEventListener('click', () => {


    if (api.gameData.connected) {
      api.unlockSpot(selectedSpot.uuid)
      document.querySelector('.fiche-spot').classList.remove('open', 'last-fiche-opened')
      document.body.classList.remove('fiche-opened')

    } else {
      location.href = 'login.html'
    }



  })

  document.body.classList.add('fiche-opened')
}


//filter map

const addFilter = (name, color) => {

  document.querySelector('.filter-wrapper').innerHTML = `
<div class="filter filter-map ${color}">
${name}
<div class="close-filter"><img src="../img/icon/white/close.svg"></div>
</div>`


  document.querySelector('.filter-map').addEventListener('click', () => {
    resetFilters()
  })

}


export const resetFilters = () => {
  addPoint(wemap)
  document.querySelector('.filter-map').remove()
  saveFilter = ""
  saveFilterType = ""


  const filters = document.querySelectorAll('.filter')

  filters.forEach(f => {
    f.classList.remove('active')
  })

  showNearestSpots(api.gameData.userLocation, document.querySelector('.spot-list-map'), true);
}

//add point

export var pinpoints = [];

var saveFilter, saveFilterType;

export const addPoint = (map, filter, mainMap, filter_type) => {

  if (mainMap) {
    wemap = map;
  }

  pinpoints = []

  var pointsList = api.allPoints;

  //console.log("pointsList",pointsList)


  if (filter && filter_type == "success") {
    saveFilter = filter;
    saveFilterType = filter_type

    let selectedSuccess = api.allSuccess.filter(success => {
      return success.uuid == filter;
    })

    addFilter(selectedSuccess[0].name[api.gameData.lang], 'pink')
    pointsList = pointsList.filter(point => {
      let showPOI = false;

      selectedSuccess[0].points.forEach(success_point => {
        if (success_point.uuid == point.uuid) {
          showPOI = true;
        }
      })

      return showPOI;
    })

  }

  if (filter && filter_type == "success_around") {
    saveFilter = filter;
    saveFilterType = filter_type

    let selectedSuccess = api.allSuccess.filter(success => {
      return success.uuid == filter;
    })

    addFilter(selectedSuccess[0].name[api.gameData.lang], 'pink')
    pointsList = pointsList.filter(point => {
      let showPOI = false;
      showPOI = point.zipcode < 76000 && point.zipcode >= 75000
      return showPOI;
    })

  }


  if (filter == "all_spot" && filter_type == "global") {
    saveFilter = filter;
    saveFilterType = filter_type

    addFilter("Tous les spots", 'purple')

    pointsList = pointsList.filter(point => {
      let showPOI = false;
      showPOI = point.type == "classic"
      return showPOI;
    })

  }


  if (filter == "all_game" && filter_type == "global") {
    saveFilter = filter;
    saveFilterType = filter_type

    addFilter("Tous les jeux", 'orange')

    pointsList = pointsList.filter(point => {
      let showPOI = false;
      showPOI = point.type == "game"
      return showPOI;
    })

  }


  if (filter == "all_event" && filter_type == "global") {
    saveFilter = filter;
    saveFilterType = filter_type

    addFilter("Tous les événements", 'blue')

    pointsList = pointsList.filter(point => {
      let showPOI = false;
      showPOI = point.type == "event"
      return showPOI;
    })

  }


  if (filter && filter_type == "game") {
    saveFilter = filter;
    saveFilterType = filter_type

    addFilter(gameName[filter][api.gameData.lang], 'orange')

    pointsList = pointsList.filter(point => {
      let showPOI = false;

      if (point.games.length > 0 && point.games[0].type == filter) {
        showPOI = true;
      }

      return showPOI;
    })

  }


  //console.warn('filter:', filter)
  //console.log(pointsList)

  pointsList.forEach((poi, index) => {

    let picto = api.media_path_map + "purple.svg";

    if (poi.hidden) {
      picto = api.media_path_map + "warning_purple.svg";
    }

    if (poi.type == "event") {
      picto = api.media_path_map + "blue.svg";
    }

    if (poi.type == "game") {
      picto = api.media_path_map + "orange.svg";

      if (poi.hidden) {
        picto = api.media_path_map + "warning_orange.svg";
      }

    }


    if (poi.visited) {
      picto = api.media_path_map + "purple_checked.svg";

      if (poi.type == "event") {
        picto = api.media_path_map + "blue_checked.svg";
      }

      if (poi.type == "game") {
        picto = api.media_path_map + "orange_checked.svg";
      }
    }

    let partner = false;

    if (poi.partner_id) {

      partner = true
      api.allPartners.forEach(partner => {
        if (partner.id == poi.partner_id && poi.visited) {
          picto = api.media_path + partner.image_2
        }
        if (partner.id == poi.partner_id && !poi.visited) {
          picto = api.media_path + partner.image
        }
      })

    }

    let image = poi.image ? api.media_path + poi.image : '../img/default.png'



    //console.log(poi.uuid,poi.name[api.gameData.lang])
    pinpoints.push({
      id: poi.uuid,
      name: poi.name[api.gameData.lang],
      place_type: poi.place,
      latitude: poi.latitude,
      longitude: poi.longitude,
      description: poi.description[api.gameData.lang],
      link: poi.external_link,
      type: 1,
      address: `${poi.address} <br>${poi.zipcode} ${poi.city}`,
      zipcode: poi.zipcode,
      image_url: picto,
      media_url: image,
      credits: poi.credits,
      visited: poi.visited,
      poi_type: poi.type,
      category: poi.category,
      games: poi.games,
      date_start: poi.date_start,
      date_end: poi.date_end,
      parent_uuid: poi.parent_uuid,
      partner: partner,
      limited_access: 1
    })
  })


  map.setPinpoints(pinpoints);

  var empty = []

  /*
  setTimeout(() => {
    map.setPinpoints(empty);
    alert('Empty !')
  }, 3500)


  setTimeout(() => {
    map.setPinpoints(pinpoints);
    alert('full !')
  }, 7000)

  */

}


//show cards

export var nearestPoints = [];

export const showNearestSpots = (userLocation, target, hideNotif = false) => {


  //console.warn('showNearestSpots', hideNotif)
  api.gameData.spotNear = []
  api.gameData.gameNear = []


  pinpoints.forEach(point => {

    //console.log(point.name)
    point.distance = distance(point.latitude, point.longitude, userLocation.latitude, userLocation.longitude)

    findPointById(point.id).distance = point.distance

    if (point.distance < 100 && !point.visited && !hideNotif) {
      //console.warn('SPOT NEAR', point.id)
      api.gameData.spotNear.push(point.id)
    }


    if (point.distance < 100 && findPointById(point.id).games.length > 0 && api.checkPoiVisited(point.id)) {
      //console.warn('GAME NEAR', point.id)
      api.gameData.gameNear.push(point.id)
    }



  })



  global.showSpotNotif()
  global.refreshSpotNotif()

  //api.checkNotif()

  nearestPoints = pinpoints.sort((a, b) => a.distance - b.distance)
  //console.log(nearestPoints)


  if (target) {

    target.innerHTML = "";

    nearestPoints.forEach((point, index) => {

      if (index < 7) {

        //console.log(point.name,point.visited)

        target.innerHTML += `
          <div class="spot-card-main ${point.poi_type} ${point.partner ? "partner" : ""}" data-index="${point.id}">
          <div class="spot-card">
              <div class="spot-card-info">${write_distance(point.distance)}</div>
              <div class="spot-card-circle ${point.visited == true ? "checked" : ""}">
  
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="white"/>
  </svg>
  
              </div>
              <img src="${point.media_url}" class="spot-card-bkg">
          </div>
          <div class="spot-card-title">${point.name}</div>
          <div class="spot-card-type">${point.category[api.gameData.lang]}</div>
      </div>
          `
      }
    })

    document.querySelectorAll('.spot-card-main').forEach((card, index) => {

      card.addEventListener('click', () => {
        //console.log(nearestPoints[index].id)
        if (document.body.getAttribute('id') == "map") {
          let target = { latitude: nearestPoints[index].latitude, longitude: nearestPoints[index].longitude }
          //wemap.centerTo(target, 16.5)
          setTimeout(() => {
            //openFiche(nearestPoints[index].id)
          }, 500)

          openFiche(nearestPoints[index].id)

        } else {
          openFiche(nearestPoints[index].id)
        }



      })

    })
  }


  if (selectedSpot) {
    updateFicheWithDistance()
  }
}



//SUCCESS


export const showSuccess = () => {
  document.querySelector('#list-sports').innerHTML = ""
  document.querySelector('#list-jeux').innerHTML = ""
  document.querySelector('#list-paris').innerHTML = ""
  document.querySelector('#list-transverses').innerHTML = ""

  api.allSuccess.forEach(success => {
    //console.log(success)

    let percent = 0;
    let validated = "";

    if (success.objective > 0) {
      percent = tools.clamp(Math.round(success.count / success.objective * 100), 0, 100)

      if (api.checkSuccesUnlocked(success.uuid)) {
        validated = "checked";
        percent = 100;
      }
    }

    //console.log(success.category)


    if (success.category) {

      document.querySelector('#list-' + success.category).innerHTML += `
      <div class="success-card-main ${validated}" data-uuid="${success.uuid}">
              <div class="success-card">
                  <div class="success-card-info">${success.score} pts</div>
                  <div class="success-card-circle ${validated}"><img src="../img/icon/white/small_check.svg" class="small-check"></div>
                  <img src="${api.media_path + success.background}" class="success-card-bkg">
                  <div class="success-card-gradient"></div>
                  <div class="success-card-bottom">
                      <div class="success-badge"><img src="${api.media_path + success.badge[api.gameData.lang]}"></div>
                      <div class="success-bottom-content">
                          <div class="success-card-title">${success.name[api.gameData.lang]}</div>
                          <div class="success-content-under">
                          ${success.description_short[api.gameData.lang]}
                          </div>
                      </div>
                  </div>
                  <div class="success-card-progress">
                      <div class="success-card-bar" style="width:${percent}%"></div>
                  </div>
              </div>

              <div class="success-card-description">

              </div>
          </div>`


    }
  })


  document.querySelectorAll('.success-card-main').forEach(card => {
    card.addEventListener('click', (e) => {
      openSuccess(e.currentTarget.dataset.uuid)
    })
  })

}


//open success
export const openSuccess = (succes_id) => {

  zindex++;
  if (!document.querySelector('.fiche-success').classList.contains('open')) {
    document.querySelector('.fiche-success').style.zIndex = zindex;
  }


  document.querySelector('.fiche-success .fiche-content').scrollTop = 0

  if (document.querySelector('.last-fiche-opened')) {
    document.querySelector('.last-fiche-opened').classList.remove('last-fiche-opened')
  }

  document.querySelector('.fiche-success').classList.add('last-fiche-opened')

  //find success

  let selectedSuccess = api.allSuccess.filter(success => {
    return success.uuid == succes_id;
  })

  let percent = tools.clamp(Math.round(selectedSuccess[0].count / selectedSuccess[0].objective * 100), 0, 100)

  if (api.checkSuccesUnlocked(succes_id)) {
    percent = 100;
    document.querySelector('.fiche-success .check').classList.add('checked')
    document.querySelector('.fiche-success-info').classList.add('none')
  } else {
    document.querySelector('.fiche-success .check').classList.remove('checked')
    document.querySelector('.fiche-success-info').classList.remove('none')
  }



  document.querySelector('.fiche-success .fiche-main-picture').setAttribute('src', api.media_path + selectedSuccess[0].background);
  document.querySelector('.fiche-success-badge').setAttribute('src', api.media_path + selectedSuccess[0].badge[api.gameData.lang]);

  document.querySelector('.fiche-success-title').innerHTML = selectedSuccess[0].name[api.gameData.lang];
  document.querySelector('.fiche-success-description').innerHTML = selectedSuccess[0].description_long[api.gameData.lang];
  document.querySelector('.fiche-success-objectif').innerHTML = `${selectedSuccess[0].count}/${selectedSuccess[0].objective}`;
  document.querySelector('.fiche-success-bar').style.width = percent + "%";


  if (api.gameData.lang == "fr") {
    document.querySelector('.fiche-success-points').innerHTML = `${selectedSuccess[0].score} points à gagner`;
  } else {
    document.querySelector('.fiche-success-points').innerHTML = `${selectedSuccess[0].score} points to win`;
  }

  document.querySelector('.fiche-success').classList.add('open', 'last-fiche-opened')


  //console.log(selectedSuccess[0].type)

  document.querySelector('.fiche-success-list').innerHTML = ""

  if (selectedSuccess[0].type == "collection") {

    selectedSuccess[0].points.forEach(point => {

      let visited = api.checkPoiVisited(point.uuid) ? "checked" : "";

      var poi = api.getSpotData(point.uuid)

      let image = poi.image ? api.media_path + poi.image : '../img/default.png'

      let type = poi.category ? poi.category[api.gameData.lang] : ""

      document.querySelector('.fiche-success-list').innerHTML += `
      <div class="fiche-success-poi" data-uuid="${point.uuid}">
     <div class="fiche-poi-left">
         <img class="fiche-poi-img" src="${image}">
     </div>
     <div class="fiche-poi-right">
         <div class="fiche-poi-title">${poi.name[api.gameData.lang]}</div>
         <div class="fiche-poi-under">${type}</div>
     </div>
     <div class="fiche-poi-check ${visited}">
     <img src="../img/icon/white/small_check.svg" class="small-check">
     </div>
     </div>`;
    })

    document.querySelectorAll('.fiche-success-poi').forEach(poi => {
      poi.addEventListener('click', (e) => {
        openFiche(e.currentTarget.dataset.uuid)
      })
    })


    //cta
    document.querySelector('.fiche-success .fiche-bottom').classList.remove('none')
    document.querySelector('#cta-filter span').innerHTML = api.gameData.lang == "fr" ? "Voir sur la carte" : "See on the map";
    document.querySelector('#cta-filter').setAttribute('href', 'map.html?filter=' + selectedSuccess[0].uuid + "&filter_type=success")

    document.querySelector('.fiche-success-list').classList.remove('around');

  } else if (selectedSuccess[0].type == "collection_around") {

    var arround = 75000;

    for (let i = 1; i < 21; i++) {

      arround++;

      let title = i == 1 ? i + "<sup>er</sup>" : i + "<sup>ème</sup>";
      let visited = api.checkZipVisited(arround) ? "checked" : "";
      console.log(arround, visited)

      document.querySelector('.fiche-success-list').classList.add('around');

      document.querySelector('.fiche-success-list').innerHTML += `
      <div class="fiche-success-around ${visited}">
      <div class="">${title}</div>
     <div class="fiche-poi-check ${visited}">
     <img src="../img/icon/white/small_check.svg" class="small-check">
     </div>
     </div>`;
    }

    //cta
    document.querySelector('.fiche-success .fiche-bottom').classList.remove('none')
    document.querySelector('#cta-filter span').innerHTML = api.gameData.lang == "fr" ? "Voir sur la carte" : "See on the map";
    document.querySelector('#cta-filter').setAttribute('href', 'map.html?filter=' + selectedSuccess[0].uuid + "&filter_type=success_around")


  } else {
    document.querySelector('.fiche-success .fiche-bottom').classList.add('none')

  }


  if (selectedSuccess[0].type == "profile_completed") {

    if (!api.checkSuccesUnlocked(selectedSuccess[0].uuid) && api.gameData.connected) {
      document.querySelector('.fiche-success .fiche-bottom').classList.remove('none')
      document.querySelector('#cta-filter span').innerHTML = api.gameData.lang == "fr" ? "Compléter le profil" : "Complete the profile";
      document.querySelector('#cta-filter').setAttribute('href', ' update_account.html')

    } else {
      document.querySelector('.fiche-success .fiche-bottom').classList.add('none')
    }
  }

  document.body.classList.add('fiche-opened')
}


//success home


export const showSuccessHome = () => {


  const getPercent = (success) => {
    let percent;

    if (success.objective > 0) {
      percent = tools.clamp(Math.round(success.count / success.objective * 100), 0, 100)
    } else {
      percent = 0
    }
    return percent
  }

  let homeSuccess = api.allSuccess
  homeSuccess.sort((a, b) => getPercent(b) - getPercent(a))


  var limit = 0;

  homeSuccess.forEach(success => {


    if (limit < 12) {
      //console.log(success)

      let percent = 0;
      let validated = "";

      if (success.objective > 0) {
        percent = tools.clamp(Math.round(success.count / success.objective * 100), 0, 100)

        if (api.checkSuccesUnlocked(success.uuid)) {
          validated = "checked";
          percent = 100;
        }
      }

      //console.log(success.category)


      if (success.category && percent < 100) {

        limit++
        document.querySelector('.swiper-success-home').innerHTML += `
      <div class="success-card-main " data-uuid="${success.uuid}">
              <div class="success-card">
                  <div class="success-card-info">${success.score} pts</div>
                  <div class="success-card-circle ${validated}"><img src="../img/icon/white/small_check.svg" class="small-check"></div>
                  <img src="${api.media_path + success.background}" class="success-card-bkg">
                  <div class="success-card-gradient"></div>
                  <div class="success-card-bottom">
                      <div class="success-badge"><img src="${api.media_path + success.badge[api.gameData.lang]}"></div>
                      <div class="success-bottom-content">
                          <div class="success-card-title">${success.name[api.gameData.lang]}</div>
                          <div class="success-content-under">
                          ${success.description_short[api.gameData.lang]}
                          </div>
                      </div>
                  </div>
                  <div class="success-card-progress">
                      <div class="success-card-bar" style="width:${percent}%"></div>
                  </div>
              </div>

              <div class="success-card-description">

              </div>
          </div>`


      }
    }
  })


  document.querySelectorAll('.success-card-main').forEach(card => {
    card.addEventListener('click', (e) => {
      openSuccess(e.currentTarget.dataset.uuid)
    })
  })

}


//score


export const showScore = (score, target) => {

  var currentLevel = 0

  api.gameData.levels.forEach((level, index) => {
    if (index > 0 && index < api.gameData.levels.length) {
      if (score >= api.gameData.levels[index - 1] && score < api.gameData.levels[index]) {
        currentLevel = index - 1
      }
    }
  })

  //console.log(api.gameData.levels[currentLevel], api.gameData.levels[currentLevel+1])

  if (document.querySelector(target)) {


    document.querySelector(target + ' .big-score').innerHTML = score
    document.querySelector(target + ' .lvl-score').innerHTML = "lvl" + currentLevel

    let angle = tools.range(api.gameData.levels[currentLevel], api.gameData.levels[currentLevel + 1], 35, 325, score)
    document.querySelector(target + ' .top-circle').style.transform = `rotate(${angle}deg)`

    let trait = tools.range(api.gameData.levels[currentLevel], api.gameData.levels[currentLevel + 1], -31, -315, score)
    document.querySelector(target + ' .circle-score').style.strokeDashoffset = trait



  }


  /*
  if(document.querySelector('.header-account')){
    document.querySelector('.header-score').innerHTML = score + "pts"
    document.querySelector('.header-level').innerHTML = "lvl" + currentLevel
  }
  */

}


export const showScoreHeader = () => {

  if (api.gameData.connected) {
    document.querySelector('.header-score').innerHTML = api.localData.score + "pts"

    let currentLevel = 0

    if (api.localData.level) {
      currentLevel = api.localData.level.id
    }


    document.querySelector('.header-level').innerHTML = "lvl" + currentLevel

    let header_circle = tools.range(api.gameData.levels[currentLevel], api.gameData.levels[currentLevel + 1], 302, 0, api.localData.score)

    if (document.querySelector('.header-circle-progress')) {
      document.querySelector('.header-circle-progress').style.strokeDashoffset = header_circle
    }
  }


}


let updateScore;
let newLevel = false

export const animScore = (start, end, target) => {


  let score = start;

  clearInterval(updateScore)
  updateScore = setInterval(() => {

    if (document.querySelector('.swiper-wrapper .home-score')) {

      if (score > end) {
        score = end;
        clearInterval(updateScore)

        setTimeout(() => {
          global.slideToNextNotif()
        }, 1000)
      }

      showScore(score, target)


      if (document.querySelector('.swiper-wrapper .home-score').classList.contains('anim')) {
        score += Math.round((end - start) / 50)
      }

    } else {
      clearInterval(updateScore)
    }


  }, 50)


}

export const showProfile = (firstname, lastname, country, target) => {

  if (target == null) {
    target = ""
  }

  if (country != null) {
    document.querySelector(target + ' .rank-flag').setAttribute('src', `../img/countries/1x1/${country}.svg`);
  }

  //console.log(firstname, lastname)
  document.querySelector(target + ' .rank-letters span').innerHTML = firstname.substring(0, 1) + lastname.substring(0, 1);

}


export const RefreshPoints = () => {

  setTimeout(() => {
    var empty = []
    wemap.setPinpoints(empty);
  }, 400)


  // console.warn('REFRESH SPOTS', document.body.getAttribute("id"))
  //console.log(document.body.getAttribute("id"))


  setTimeout(() => {

    //console.warn('REFRESH TIMEOUT', document.body.getAttribute("id"))

    if (document.body.getAttribute("id") == "map") {
      addPoint(wemap, saveFilter, true, saveFilterType)
      showNearestSpots(api.gameData.userLocation, document.querySelector('.spot-list-map'), true);
    }

    if (document.body.getAttribute("id") == "home") {
      showNearestSpots(api.gameData.userLocation, document.querySelector('.swiper-spot-home'))
    }

  }, 500)




}



//GIFT

//open gift
export const openGift = (uuid, giftData) => {

  let gift = giftData ? giftData : findGiftById(uuid)

  //console.log(gift)

  if (document.querySelector('.last-fiche-opened')) {
    document.querySelector('.last-fiche-opened').classList.remove('last-fiche-opened')
  }

  document.querySelector('.fiche-gift').classList.add('last-fiche-opened')

  document.body.classList.add('fiche-opened')
  document.querySelector('.fiche-gift').classList.add('open')
  document.querySelector('.fiche-gift .fiche-content').scrollTop = 0

  document.querySelector('.fiche-gift-type').innerHTML = gift.name[api.gameData.lang];
  document.querySelector('.fiche-gift-title').innerHTML = gift.description[api.gameData.lang];

  document.querySelector('#gift-description').innerHTML = gift.description_long[api.gameData.lang];


  let code;
  let unlock;

  if (api.gameData.connected) {

    api.localData.gifts.forEach(gift => {
      if (gift.uuid == uuid) {
        code = gift.promo_code
        unlock = true;
      }
    })
  }


  if (unlock) {
    document.querySelector('#how-to-get').innerHTML = gift.how_to_get[api.gameData.lang];
    document.querySelector('#how-to-get-wrapper').classList.remove('none');
    document.querySelector('#how-to-win-wrapper').classList.add('none');
    document.querySelector('.fiche-gift .check').classList.add('checked');
  } else {
    document.querySelector('#how-to-win').innerHTML = gift.how_to_win[api.gameData.lang];
    document.querySelector('#how-to-get-wrapper').classList.add('none');
    document.querySelector('#how-to-win-wrapper').classList.remove('none');
    document.querySelector('.fiche-gift .check').classList.remove('checked');
  }

  //console.log(code)

  if (code) {
    document.querySelector('.fiche-code').innerHTML = code;
    document.querySelector('.fiche-code').classList.remove('none');
  } else {
    document.querySelector('.fiche-code').classList.add('none')
  }




  let image = gift.image ? api.media_path + gift.image : '../img/default.png'
  document.querySelector('.fiche-gift .fiche-main-picture').setAttribute('src', image);


  if (gift.link_legals[api.gameData.lang]) {
    document.querySelector('#fiche-legals').setAttribute('href', gift.link[api.gameData.lang]);
    document.querySelector('#fiche-legals').classList.remove('none')
  } else {
    document.querySelector('#fiche-legals').classList.add('none')
  }


  if (gift.link[api.gameData.lang]) {
    document.querySelector('.fiche-gift #cta-get').setAttribute('href', gift.link[api.gameData.lang]);
    document.querySelector('.fiche-code').setAttribute('href', gift.link[api.gameData.lang]);

    document.querySelector('.fiche-gift .fiche-bottom').classList.remove('none')
  } else {
    document.querySelector('.fiche-gift .fiche-bottom').classList.add('none')
  }
}



export const centerSpot = () => {
  if (document.body.getAttribute('id') == "map") {

    let target = { latitude: selectedSpot.latitude, longitude: selectedSpot.longitude }
    wemap.centerTo(target, 16.5)

    document.querySelector('.fiche-spot').classList.remove('open', 'last-fiche-opened')
    document.querySelector('.fiche-success').classList.remove('open', 'last-fiche-opened')

    document.body.classList.remove('fiche-opened')
  } else {
    location.href = `map.html?spot=${selectedSpot.uuid}`
  }
}


export const getSpotGameRecord = (game_uuid, spot_uuid) => {


  let record = 0;

  if (api.gameData.connected) {
    api.localData.played_game.forEach(game => {
      //console.log(game)
      if (game.game_uuid == game_uuid && game.point_uuid == spot_uuid) {
        record = game.score_total;
      }
    })

  }

  return record;
}



const updateFicheWithDistance = () => {

  //console.log('selectedSpot.distance', selectedSpot.distance)

  document.querySelector('#cta-unlock').classList.add('none')
  document.querySelector('#cta-play').classList.add('none')
  document.querySelector('#cta-navigate').classList.add('none')
  document.querySelector('#cta-map').classList.add('none')



  if (selectedSpot.distance) {
    document.querySelector('.fiche-distance').innerHTML = write_distance(selectedSpot.distance);
    document.querySelector('.fiche-distance').classList.remove('none')
    document.querySelector('.point-distance').classList.remove('none')

  } else {
    document.querySelector('.fiche-distance').classList.add('none')
    document.querySelector('.point-distance').classList.add('none')
  }


  if (selectedSpot.distance < 100) {

    //console.log('NEAR')

    if (selectedSpot.visited) {
      document.querySelector('.game-line').classList.add('unlock')

      if (findPointById(selectedSpot.uuid).games.length > 0) {
        document.querySelector('#cta-play').classList.remove('none')
      } else {
        document.querySelector('#cta-play').classList.add('none')
      }


    } else {
      document.querySelector('.game-line').classList.remove('unlock')
      document.querySelector('#cta-unlock').classList.remove('none')
    }




  } else {

    //console.log('FAR')
    document.querySelector('.game-line').classList.remove('unlock')

    if (document.body.getAttribute('id') == 'map') {
      document.querySelector('#cta-navigate').classList.remove('none')
    } else {
      document.querySelector('#cta-map').classList.remove('none')

    }

  }


}
