import '../css/global.css'
import * as api from "./api.js"
import * as global from './global.js'
import * as tools from './tools.js'
import * as spot_tools from './spot_tools.js'
import * as language from './_translation.js'



//debug
/*
let params = new URLSearchParams(location.search);
if (params.get('debug')) {
    api.localData.debug = params.get('debug')
    api.saveLocalData()
    alert('Debug mode ' + api.localData.debug)
}
*/



const homeReady = () => {

    global.addNav();
    global.addFiche();
    global.addTop();
    language.translate();

    let zoomNear = 17;


    spot_tools.showScore(api.localData.score, '.score-main')

    //success

    let spots_visited = []
    let total_spot = api.allPoints.filter(spot => {
        return spot.type != "event"
    })

    if (api.localData.visited_poi) {
        spots_visited = api.localData.visited_poi.filter(spot => {
            return spot.type != "event"
        })
    }



    document.querySelector('#box-spot .box-number').innerHTML = spots_visited.length;
    document.querySelector('#box-spot .box-total').innerHTML = "/" + total_spot.length;


    document.querySelector('#box-success .box-number').innerHTML = api.localData.success.length;
    document.querySelector('#box-success .box-total').innerHTML = "/" + api.allSuccess.length;

    //jeux


    let nGame = 0
    api.allPoints.forEach(spot => {
        if (spot.games.length > 0) {
            nGame++
        }
    })


    document.querySelector('#box-games .box-number').innerHTML = api.gameData.connected ? api.localData.played_game.length : 0;
    document.querySelector('#box-games .box-total').innerHTML = "/" + nGame;


    if (api.gameData.connected) {
        //spot_tools.animScore(0, api.localData.score, '.score-main')
    } else {
        document.querySelector('.header-score').innerHTML = ""
        document.querySelector('.header-level').innerHTML = ""
        document.querySelector('.home-score').remove()
        document.querySelector('.home-data').remove()
    }


    //home map

    var updateReady = true
    var centerReady=true

    var livemap = wemap.v1.createLivemap(document.querySelector('.home-map'), {
        emmid: 26407,
        token: 'GK7E6PDBLJ3IW95UY7M0ML0SZ',
    });



    livemap.waitForReady().then(function () {


        //livemap.setUserLocation(api.gameData.userLocation);
        livemap.centerTo(api.gameData.userLocation, 17)

        //livemap.disablePositioningSystem()
        document.querySelector('.home-map').classList.add('ready')

        spot_tools.addPoint(livemap);
        spot_tools.showNearestSpots(api.gameData.userLocation, document.querySelector('.swiper-spot-home'))

        livemap.getUserLocation().then(function (location) {
            spot_tools.showNearestSpots(location, document.querySelector('.swiper-spot-home'));
          });

        livemap.addEventListener('userLocationUpdated', (data) => {

            api.gameData.userLocation = data.userLocation;
            api.saveGameData()
            livemap.centerTo(data.userLocation, zoomNear)

            if (updateReady) {
                spot_tools.showNearestSpots(data.userLocation, document.querySelector('.swiper-spot-home'));
                updateReady = false;
                setTimeout(() => {
                    updateReady = true
                }, 3000)

            }

        })


        livemap.getUserLocation().then(function (location) {
            livemap.centerTo(location, zoomNear)
            spot_tools.showNearestSpots(data.userLocation, document.querySelector('.swiper-spot-home'));

        });


        window.onfocus = function (ev) {
            livemap.getUserLocation()
        };
    })


    //success
    spot_tools.showSuccessHome()

    //game

    api.gameData.unlocked_games.forEach(game => {
        //console.log(game)
        document.querySelector('#game-' + game).classList.add('unlock')
    })

    if (api.mode == "preprod" || api.mode == "prod") {
        document.querySelectorAll('.game-card-main').forEach(game => {
            //console.log(game)
            game.classList.add('unlock')
        })
    }



    //cadeaux


    api.allGifts.forEach(gift => {

        //console.log(gift)

        let type = [
            {
                fr: "Partenaire",
                en: "Partner"
            },
            { fr: "Spot24", en: "spot24" }
        ]

        let type_text = type[0][api.gameData.lang]

        if (gift.type == "level") {
            type_text = type[1][api.gameData.lang]
        }


        let unlock;

        if (api.gameData.connected) {
            api.localData.gifts.forEach(g => {
                if (g.uuid == gift.uuid) {
                    unlock = true;
                }
            })
        }


        let checked = unlock ? "checked" : "";

        let image = gift.image ? api.media_path + gift.image : '../img/default.png'

        document.querySelector('.swiper-gift-home').innerHTML += `
        <div class="gift-card-main gift" data-id="${gift.uuid}">
      
        <div class="gift-card">
        <div class="spot-card-circle ${checked}"><svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z" fill="white"/>
        </svg></div>
        <img src="${image}" class="gift-card-img">
        </div>
        <div class="spot-card-title">${gift.description[api.gameData.lang]}</div>
        <div class="gift-card-type">${gift.name[api.gameData.lang]}</div>
        </div>
        `


    })

    document.querySelectorAll('.gift-card-main').forEach(card => {
        card.addEventListener('click', (e) => {
            spot_tools.openGift(e.currentTarget.dataset.id)
        })
    })


    //countdown


    api.getPrize((prizes) => {


        //console.warn('prize', prizes, prizes.length)

        if (prizes.length > 0) {

            var now = new Date().getTime();
            var allDates = prizes.sort((a, b) => a.date - b.date)


            allDates = allDates.filter((date) => {
                var prizeDATE = new Date(date.date).getTime()
                return prizeDATE > now
            })


            if (allDates.length > 0) {
                //console.log('allDates', allDates)

                var countDownDate = new Date(allDates[0].date).getTime();

                const formatTime = (n) => {

                    if (n < 10) {
                        return "0" + n
                    } else {
                        return n;
                    }
                }


                // Update the count down every 1 second
                var x = setInterval(function () {

                    now = new Date().getTime();

                    // Find the distance between now and the count down date
                    var distance = countDownDate - now;

                    // Time calculations for days, hours, minutes and seconds
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    // Display the result in the element with id="demo"
                    document.querySelector("#days").innerHTML = formatTime(days);
                    document.querySelector("#hours").innerHTML = formatTime(hours);
                    document.querySelector("#min").innerHTML = formatTime(minutes);
                    document.querySelector("#sec").innerHTML = formatTime(seconds);

                    // If the count down is finished, write some text
                    //console.log(distance)

                    if (distance < 0) {
                        clearInterval(x);
                        document.querySelector('.home-countdown').classList.add('none')
                    }
                }, 1000);

            } else {
                document.querySelector('.home-countdown').classList.add('none')
            }
        }
        else {
            document.querySelector('.home-countdown').classList.add('none')
        }
    })

    /// classement


    api.getRank((ranks) => {


        if (ranks.length > 2) {
            //console.log(ranks)

            document.querySelector('.first .rank-flag').setAttribute('src', `../img/countries/1x1/${ranks[0].country !== null ? ranks[0].country : "xx"}.svg`);
            document.querySelector('.second .rank-flag').setAttribute('src', `../img/countries/1x1/${ranks[1].country !== null ? ranks[1].country : "xx"}.svg`);
            document.querySelector('.third .rank-flag').setAttribute('src', `../img/countries/1x1/${ranks[2].country !== null ? ranks[2].country : "xx"}.svg`);

            document.querySelector('.first .rank-name').innerHTML = ranks[0].firstname + " " + ranks[0].lastname.substring(0, 1) + ".";
            document.querySelector('.second .rank-name').innerHTML = ranks[1].firstname + " " + ranks[1].lastname.substring(0, 1) + ".";
            document.querySelector('.third .rank-name').innerHTML = ranks[2].firstname + " " + ranks[2].lastname.substring(0, 1) + ".";


            document.querySelector('.first .rank-letters').innerHTML = ranks[0].firstname.substring(0, 1) + ranks[0].lastname.substring(0, 1);
            document.querySelector('.second .rank-letters').innerHTML = ranks[1].firstname.substring(0, 1) + ranks[1].lastname.substring(0, 1);
            document.querySelector('.third .rank-letters').innerHTML = ranks[2].firstname.substring(0, 1) + ranks[2].lastname.substring(0, 1);


            document.querySelector('.first .rank-pseudo').innerHTML = "@" + ranks[0].pseudo;
            document.querySelector('.second .rank-pseudo').innerHTML = "@" + ranks[1].pseudo;
            document.querySelector('.third .rank-pseudo').innerHTML = "@" + ranks[2].pseudo;

            document.querySelector('.first .rank-score').innerHTML = ranks[0].score + "pts";
            document.querySelector('.second .rank-score').innerHTML = ranks[1].score + "pts";
            document.querySelector('.third .rank-score').innerHTML = ranks[2].score + "pts";

            if (api.gameData.connected) {
                api.getRankUser(api.gameData.uuid, (rank) => {
                    if (rank < 4) {
                        document.querySelector('#rank' + (rank - 1)).classList.add('myrank')
                    }

                })
            }

        } else {
            document.querySelector('.home-ranking').classList.add('none')
        }
    })



    //events

    /*
    var allEvents = []

    api.allPoints.forEach(point => {

        if (point.type == "event") {
            allEvents.push(point)
        }

    })



    allEvents.sort((a, b) => {

        if (a.date_start > b.date_start) {
            return 1
        }

        if (a.date_start < b.date_start) {
            return -1
        }

        if (a.date_star == b.date_start) {
            return 0
        }

    })


    //console.log(allEvents[0])

    var eventDate = new Date(allEvents[0].date_start);
    document.querySelector('.event-card-img').setAttribute('src', api.media_path + allEvents[0].image)

    document.querySelector('.event-card-day').innerHTML = eventDate.getDate();
    document.querySelector('.event-card-month').innerHTML = eventDate.toLocaleString('fr', { month: 'long' });
    document.querySelector('.event-card-title').innerHTML = allEvents[0].name.fr;
    document.querySelector('.event-card-type').innerHTML = "type d'event";

    /*
        allEvents.forEach((event, index) => {
            if (index > 0) {
    
                let eventDate = new Date(event.date_start);
    
                document.querySelector('.event-list').innerHTML += ` 
                   <div class="small-event">
    <div class="small-event-date">
        <div class="small-event-day">${eventDate.getDate()}</div>
        <div class="small-event-month">${eventDate.toLocaleString('fr', { month: 'long' })}</div>
    </div>
    
    <div class="small-event-content">
        <div class="small-event-title">${event.name.fr}</div>
        <div class="small-event-type">type d'event</div>
    </div>
    </div>`
    
            }
        })
    
        */


    document.querySelector('#cta-rules').setAttribute('href', `../pdf/rules_${api.gameData.lang}.pdf`)
    document.querySelector('#cta-policy').setAttribute('href', `../pdf/policy_${api.gameData.lang}.pdf`)
}


//api.getLocalData()

api.getFirstData(() => {
    homeReady()
})


document.querySelector('#cta-cookie').addEventListener("click", () => {
    Didomi.preferences.show()
})
