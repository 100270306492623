import * as api from './api.js'


export const lerp = (x, y, a) => x * (1 - a) + y * a;
export const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x, y, a) => clamp((a - x) / (y - x));
export const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}


export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function readTextFile(file, callback) {
  var rawFile = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4 && rawFile.status == "200") {
      callback(rawFile.responseText);
    }
  }
  rawFile.send(null);
}





export const buildCountryList = () => {
  var list = ''
  var firstTime = true

  document.querySelector('footer').innerHTML += `<aside class="fiche-countries ">

  <div class="header">
      <div class="header-back" id="back-country"><img src="../img/icon/white/back.svg" class="back-icon"></div>
      <div class="country-select-title" data-lang="choose_country">Choisissez votre pays</div>
      <div class="header-check">
          <div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12"
                  fill="none">
                  <path
                      d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z"
                      fill="black" />
              </svg></div>
      </div>
  </div>

  <div class="countries-list"></div>

</aside>`
    ;





  readTextFile("../img/countries/country.json", function (text) {
    var countries = JSON.parse(text);
    //console.log(countries)

    if (api.gameData.lang == "fr") {
      countries = countries.sort(
        (a, b) => {

          if (a.fr < b.fr) {
            return -1;
          }
          if (a.fr > b.fr) {
            return 1;
          }
          return 0;


        }
      )
    }



    let elmt = document.querySelector('.countries-list')


    countries.forEach(country => {

      let state = ""

      if (country.code == api.localData.country) {
        state = 'active'
        document.querySelector('#input-country').value = country.code
        document.querySelector('#input-country-name').value = api.gameData.lang == "fr" ? country.fr : country.name
        document.querySelector('.fiche-countries .header-check .check').classList.add('checked')
        document.querySelector('.country-mini-flag').classList.add('active')
        document.querySelector('.country-mini-flag').setAttribute('src', `../img/countries/4x3/${country.code}.svg`)
      }

      let main = country.main ? "main-country" : ""

      if (country.code == "fr" && api.gameData.lang == "fr") {
        main = "first-country"
      }

      list += `
    <div class="country-item ${state} ${main}" data-code="${country.code}" data-name="${api.gameData.lang == "fr" ? country.fr : country.name}">
    <div class="country-text">${api.gameData.lang == "fr" ? country.fr : country.name}</div>
    <img class="country-flag" src="../img/countries/4x3/${country.code}.svg">
    </div>
    `
    })








  });



  //


  const initItems = () => {
    const items = document.querySelectorAll('.country-item')

    items.forEach(item => {
      item.addEventListener('click', (e) => {
        document.body.scrollTop = 0
        document.querySelector('#input-country').value = e.currentTarget.dataset.code
        document.querySelector('#input-country-name').value = e.currentTarget.dataset.name

        if (document.body.getAttribute('id') == "update_account") {
          document.querySelector('.rank-flag').setAttribute('src', `../img/countries/1x1/${e.currentTarget.dataset.code}.svg`);
        }

        document.querySelector('.country-mini-flag').setAttribute('src', `../img/countries/4x3/${e.currentTarget.dataset.code}.svg`)

        document.querySelector('.country-mini-flag').classList.add('active')
        document.querySelector('.fiche-countries').classList.remove('open', 'last-fiche-opened')
        document.body.classList.remove('fiche-opened')


        items.forEach(i => {
          if (i != item)
            i.classList.remove('active')
        })
        e.currentTarget.classList.toggle('active')


        if (document.querySelectorAll('.country-item.active').length > 0) {
          document.querySelector('.fiche-countries .header-check .check').classList.add('checked')

        } else {
          document.querySelector('.fiche-countries .header-check .check').classList.remove('checked')
          document.querySelector('#input-country').value = ""
          document.querySelector('#input-country-name').value = ""
          document.querySelector('.country-mini-flag').classList.remove('active')

          if (document.body.getAttribute('id') == "update_account") {
            document.querySelector('.rank-flag').removeAttribute('src');
          }
        }

      })
    })

    if (document.querySelector('.country-item.active')) {
      document.querySelector('.country-item.active').scrollIntoView({ block: "center" });
    }
  }



  document.querySelector('footer').innerHTML += `<aside class="fiche-sports">

  <div class="header">
      <div class="header-back" id="back-sport"><img src="../img/icon/white/back.svg" class="back-icon"></div>
      <div class="country-select-title" data-lang="favorite_sport">Votre sport préféré</div>
      <div class="header-check">
          <div class="check"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12"
                  fill="none">
                  <path
                      d="M12.4987 0.246366C11.9582 -0.172307 11.2021 -0.0442057 10.8084 0.530689L5.18754 8.75418L2.09124 5.40167C1.63507 4.88302 0.869585 4.85802 0.382175 5.34543C-0.105236 5.82972 -0.130231 6.6452 0.329059 7.16073C0.329059 7.16073 4.09087 11.335 4.6314 11.7536C5.17192 12.1723 5.92803 12.0442 6.32171 11.4693L12.7674 2.04291C13.1611 1.46489 13.0392 0.661915 12.4987 0.246366Z"
                      fill="black" />
              </svg></div>
      </div>
  </div>

  <div class="sports-list">
  </div>

</aside>`


  let sports = [
    { id: 'basket', fr: "Basketball 3x3", en: "Basketball 3x3" },
    { id: 'bmx', fr: "BMX Freestyle", en: "BMX Freestyle" },
    { id: 'brekdance', fr: "Breakdance", en: "Breakdance" },
    { id: 'climb', fr: "Escalade", en: "Climbing" },
    { id: 'skate', fr: "Skateboard", en: "Skateboard" },
    { id: 'surf', fr: "Surf", en: "Surf" }

  ]


  sports.forEach(sport => {

    let state = ""

    if (sport.id == api.localData.favorite_sport) {
      state = 'active'
      document.querySelector('#input-sport-name').value = sport[api.gameData.lang]
      document.querySelector('#input-sport').value = sport.id
      document.querySelector('.fiche-sports .header-check .check').classList.add('checked')
    }

    document.querySelector('.sports-list').innerHTML += `<div class="sport-item ${state}" data-sport="${sport.id}">${sport[api.gameData.lang]}</div>`
  })



  const sport_items = document.querySelectorAll('.sport-item')

  sport_items.forEach(item => {
    item.addEventListener('click', (e) => {

      sport_items.forEach(i => {
        if (i != item)
          i.classList.remove('active')
      })
      e.currentTarget.classList.toggle('active')
      document.querySelector('.fiche-sports').classList.remove('open', 'last-fiche-opened')
      document.body.classList.remove('fiche-opened')
      document.querySelector('#input-sport-name').value = e.currentTarget.textContent
      document.querySelector('#input-sport').value = e.currentTarget.dataset.sport


      if (document.querySelectorAll('.sport-item.active').length > 0) {
        document.querySelector('.fiche-sports .header-check .check').classList.add('checked')

      } else {
        document.querySelector('.fiche-sports .header-check .check').classList.remove('checked')
        document.querySelector('#input-sport').value = ""
        document.querySelector('#input-sport-name').value = ""
      }


    })
  })

  document.querySelector('#back-sport').addEventListener('click', () => {
    document.querySelector('.fiche-sports').classList.remove('open', 'last-fiche-opened')
    document.body.classList.remove('fiche-opened')
  })



  document.querySelector('.sport-box').addEventListener('click', () => {
    document.querySelector('.fiche-sports').classList.add('open', 'last-fiche-opened')
    document.body.classList.add('fiche-opened')
  })

  //

  document.querySelector('#back-country').addEventListener('click', () => {
    document.querySelector('.fiche-countries').classList.remove('open', 'last-fiche-opened')
    document.body.classList.remove('fiche-opened')
  })


  document.querySelector('#input-country-name').addEventListener('click', () => {
    document.querySelector('.fiche-countries').classList.add('open', 'last-fiche-opened')


    console.log(firstTime)
    if (firstTime) {
      document.querySelector('.countries-list').innerHTML = list;
      initItems()
      firstTime = false
    }

    document.body.classList.add('fiche-opened')
  })



}

export function lerpColor(a, b, amount) {

  var ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

export const formatTime = (time) => {



  time *= 1000


  let timeToShow;
  let seconds = Math.floor(time / 1000)
  let millis = time - seconds * 1000
  millis = Math.round(millis)



  if (time > 60000) {
    let min = Math.floor(time / 60000)
    let sec = Math.floor((time - min * 60000) / 1000)

    if (sec < 10) {
      sec = "0" + sec
    }


    timeToShow = `${min}.${sec}.${millis}`;

  } else {
    timeToShow = `${seconds}.${millis}`;
  }


  return timeToShow;
}